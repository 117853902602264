import { EventEmitter, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { parseString } from 'xml2js';
import { CuentaCorrienteDTO, DetalleServicioDTO, DocumentoCobroDTO, ParametrosDocumentoDeCobro, ParametrosSeleccionDeCobro, ServicioDTO } from './consultaServicioDTO'
import { ParametrosConsultaDTO } from './../consulta/consultaDTO';
import { parseBooleans } from 'xml2js/lib/processors';
import { isNullOrUndefined } from "util";
import { CompileShallowModuleMetadata } from "@angular/compiler";


@Injectable()
export class ConsultaServiciosService {
  private UrlSOAP = "comunicabanco/comunicabanco.asmx?op=";
  private UrlApi = "Coregl/api/comunicabanco";   //URL para QA y otros ambientes
  //private UrlApi = "api/comunicabanco";   //URL para desa

  //QA en el proxy.conf.json
  /*"/Coregl/*": {
    "target" : "https://serviciosgl.minfin.gob.gt", 
    "secure" : false ,
    "logLevel": "debug",
    "changeOrigin": true
  }*/

  //private UrlApi = "api/comunicabanco";            //URL para pruebas en desa

  //desa en el proxy.conf.json
  /*"/api/*": {
    "target" : "http://localhost:62824/", 
    "secure" : false ,
    "logLevel": "debug",
    "changeOrigin": true
  }*/

  servicios: ServicioDTO[];
  servicio: ServicioDTO;
  usuario: string = 'PORTALGL';
  clave: string = 'SECRETO';
  montoServicio: number = 0;
  aplicaPagosEnLinea$ = new EventEmitter<boolean>();
  
  private headers = new HttpHeaders({
    'Content-Type': 'text/xml;charset=UTF-8'
  });

  /**
   * Declaramos esta variable que contiene el esquema del XML para realizar el POST.
   * El TIPO_CONSULTA al parecer el web service ya no lo toma en cuenta, ya que siempre devuelve lo mismo
   */
  private consultaServicios = `<?xml version="1.0" encoding="utf-8"?>
  <soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">
    <soap12:Body>
      <ConsultaServicios xmlns="http://tempuri.org/">
        <ID_CONTRIBUYENTE>|ID_CONTRIBUYENTE|</ID_CONTRIBUYENTE>
        <ID_ENTIDAD>|ID_ENTIDAD|</ID_ENTIDAD>
        <TIPO_CONSULTA>|TIPO_CONSULTA|</TIPO_CONSULTA>
      </ConsultaServicios>
    </soap12:Body>
  </soap12:Envelope>`;

  private GeneradoDocumentoCobro = `<?xml version="1.0" encoding="utf-8"?>
  <soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">
    <soap12:Body>
      <ConsultaReporte xmlns="http://tempuri.org/">
        <strUsr>|USUARIO|</strUsr>
        <strPwd>|CLAVE|</strPwd>
        <reporte>|TIPO_REPORTE|</reporte>
        <id_contribuyente>|ID_CONTRIBUYENTE|</id_contribuyente>
        <id_entidad>|ID_ENTIDAD|</id_entidad>
        <SessionUsuario>|USUARIO|</SessionUsuario>
        <cuentas_corrientes>|CUENTAS_CORRIENTES|</cuentas_corrientes>
      </ConsultaReporte>
    </soap12:Body>
  </soap12:Envelope>`;

  private DetalleDeServicio = `<?xml version="1.0" encoding="utf-8"?>
  <soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">
    <soap12:Body>
      <ConsultaDetalleServicios xmlns="http://tempuri.org/">
        <contribuyente_servicio>|ID_CONTRIBUYENTE_SERVICIO|</contribuyente_servicio>
      </ConsultaDetalleServicios>
    </soap12:Body>
  </soap12:Envelope>`;

  private CuentaCorriente = `<?xml version="1.0" encoding="utf-8"?>
  <soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">
    <soap12:Body>
      <ConsultaCuentaCorriente xmlns="http://tempuri.org/">
        <ID_SERVICIO>|ID_SERVICIO|</ID_SERVICIO>
        <ID_ENTIDAD>|ID_ENTIDAD|</ID_ENTIDAD>
        <ID_DETALLE_SERVICIO>|ID_DETALLE_SERVICIO|</ID_DETALLE_SERVICIO>
        <ID_CONTRIBUYENTE_SERVICIO>|ID_CONTRIBUYENTE_SERVICIO|</ID_CONTRIBUYENTE_SERVICIO>
      </ConsultaCuentaCorriente>
    </soap12:Body>
  </soap12:Envelope>`;

  private AplicaPagos = `<?xml version="1.0" encoding="utf-8"?>
  <soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">
    <soap12:Body>
      <Aplica_PagosOnline xmlns="http://tempuri.org/">
        <ID_ENTIDAD>|ID_ENTIDAD|</ID_ENTIDAD>
      </Aplica_PagosOnline>
    </soap12:Body>
  </soap12:Envelope>`;

  /**
   * Definimos todo lo necesario para las consultas
   * @param http
   */
  constructor(private http: HttpClient) {}

  /**
   * Realiza la consulta de los servicios que recibe el contribuyente
   * @param parametrosConsultaDTO contiene los parametros para buscar los servicios
   */
async ObtenerServicios(parametrosConsultaDTO: ParametrosConsultaDTO) {
    var Url = this.UrlApi + '/consultaservicios';

    //encabezado del api con coregl
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
  
    let bodyParametros = {
      "ID_BUSQUEDA": parametrosConsultaDTO.id_contribuyente.toString(), 
      "ID_ENTIDAD" : parametrosConsultaDTO.id_entidad.toString(),
      "TIPO_CONSULTA" : 'CONT'
    };

    // parametrosConsultaDTO.tipoConsulta.toString()
    try {
      var servicios = [];

      const ApiResponse = await this.http.post(Url, bodyParametros, {observe: 'response'}).toPromise();

      //significa que todo salió bien, se devuelve el entero del error o el conjunto de datos
      if(ApiResponse.status == 200 &&  !isNullOrUndefined(ApiResponse.body))
      {
        if(ApiResponse["body"]["data"] instanceof Array){
          //trae data entonces se arma el arreglo con la estructura correspondiente    
          for (var i=0; i < ApiResponse["body"]["data"].length; i++){
            let servicio: ServicioDTO = {
              ID_CONTRIBUYENTE_SERVICIO: 0, 
              ID_SERVICIO: 0, 
              NOMBRE_SERVICIO:'', 
              IDENTIFICADOR: '', 
              ID_CATASTRO:0, 
              CATASTRO:'',
              DIRECCION_SERVICIO: '',
              TOTAL_A_COBRAR: 0,
              ID_ENTIDAD: 0,
              ID_SERVICIO_CATASTRO: 0
            }

            servicio.ID_CONTRIBUYENTE_SERVICIO = ApiResponse["body"]["data"][i]["iD_CONTRIBUYENTE_SERVICIO"];
            servicio.ID_SERVICIO = ApiResponse["body"]["data"][i]["iD_SERVICIO"];
            servicio.NOMBRE_SERVICIO = ApiResponse["body"]["data"][i]["nombrE_SERVICIO"];
            servicio.IDENTIFICADOR = ApiResponse["body"]["data"][i]["identificador"];
            servicio.ID_CATASTRO = ApiResponse["body"]["data"][i]["iD_CATASTRO"];
            servicio.CATASTRO = ApiResponse["body"]["data"][i]["catastro"];
            servicio.DIRECCION_SERVICIO = ApiResponse["body"]["data"][i]["direccioN_SERVICIO"];
            servicio.TOTAL_A_COBRAR = parseFloat(ApiResponse["body"]["data"][i]["totaL_A_COBRAR"]);
            servicio.ID_SERVICIO_CATASTRO = ApiResponse["body"]["data"][i]["iD_SERVICIO_CATASTRO"];

            servicios.push(servicio);
          } 
        }
      }
   
      return servicios;
    }
    catch (err)
    {
    this.handleError(err);
    }
  }

  /**
   * Metodo que verifica si la municipalidad acepta pagos en linea
   */
 async AplicaPagosEnLinea(idEntidad: number) {
    var Url = this.UrlApi + '/aplicapagosonline';

    try {
      //encabezado del api con coregl
      var resultado

      let headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
    
      let bodyParametros = {
        "ID_ENTIDAD": idEntidad.toString()
      };

      const ApiResponse = await this.http.post(Url, bodyParametros, {observe: 'response'}).toPromise();

      //significa que todo salió bien, se devuelve el entero del error o el conjunto de datos
      if(ApiResponse.status == 200 &&  !isNullOrUndefined(ApiResponse.body))
      {
        resultado = ApiResponse["body"]
      }

      return resultado;
    }
    catch(error){
      this.handleError(error);
    }
  }

  /**
   * 
   * @param usuario Metodo
   */
    async PagosLineaEjercicio(id_entidad: number, ejercicio: number) {
      var respuesta;
 
      var Url = this.UrlApi + '/validapagoslinea_ejercicio';
  
      //encabezado del api con coregl
      let headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
    
      let bodyParametros = {
        "ID_ENTIDAD": id_entidad.toString(),
        "EJERCICIO": ejercicio.toString()
      };
      
      try {
        const ApiResponse = await this.http.post(Url, bodyParametros, {observe: 'response'}).toPromise();
  
        respuesta = ApiResponse.body;
        return respuesta;
  
      }
      catch (err)
      {
        this.handleError(err);
      }
    }

  /**
   * Metodo que realiza la consulta de existencia del documento de cobro
   * @param parametrosDocumentoCobro Parametros para realizar la consulta del documento de cobro
   */
  async ValidaExistenciaDocumentoCobro(parametroDocumentoDeCobro: ParametrosDocumentoDeCobro): Promise<DocumentoCobroDTO> {
    try {
      let documentoCobro: DocumentoCobroDTO = await this.GenerarDocumentoDeCobro(parametroDocumentoDeCobro);
      
      if (documentoCobro == null)
        return null;
      else    
        return documentoCobro;
    }catch(err)
    {
      this.handleError(err);
    }
  }

  /**
   * Metodo que realiza la consulta al web services para generar el documento de cobro
   */
  async GenerarDocumentoDeCobro(parametrosDocumentoCobro: ParametrosDocumentoDeCobro): Promise<DocumentoCobroDTO> {
    
    var Url = this.UrlApi + '/ConsultaReporte';

    try {
      
      let headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');  

      let bodyParametros = {
        "ID_ENTIDAD": parametrosDocumentoCobro.id_entidad.toString(), 
        "STR_USR": this.usuario, 
        "STRPWD": this.clave,
        "REPORTE" : parametrosDocumentoCobro.reporte,
        "ID_CONTRIBUYENTE" : parametrosDocumentoCobro.id_contribuyente.toString(),
        "CUENTAS_CORRIENTES" : parametrosDocumentoCobro.cuentas_corrientes,
        "ID_CONTRIBUYENTE_DOC" : parametrosDocumentoCobro.id_contribuyente_doc.toString()
      };

      const ApiResponse = await this.http.post(Url, bodyParametros, {observe: 'response'}).toPromise();

      const mdlDatos = JSON.parse(ApiResponse["body"]["data"]); 

      //significa que todo salió bien, caso contrario que devuelva objeto nulo
      if(ApiResponse.status == 200 &&  !isNullOrUndefined(ApiResponse.body))
      {
        let documentoCobro: DocumentoCobroDTO = {
          ID_DOCUMENTO: mdlDatos.mdlDocumento["0"]["IdDocumento"],
          NUMERO_DOCUMENTO : mdlDatos.mdlDocumento["0"]["NumeroDocumento"],
          DESCRIPCION : mdlDatos.mdlDocumento["0"]["Descripcion"],
          FECHA_DOCUMENTO : mdlDatos.mdlDocumento["0"]["FechaDocumento"],
          MONTO_DOCUMENTO : mdlDatos.mdlDocumento["0"]["MontoDocumento"],
          ESTADO : mdlDatos.mdlDocumento["0"]["Estado"],
          ID_PERSONA : mdlDatos.mdlDocumento["0"]["IdPersona"],
          FECHA_APROBADO : mdlDatos.mdlDocumento["0"]["FechaAprobado"],
          FECHA_REFERENCIA : mdlDatos.mdlDocumento["0"]["FechaReferencia"],
          OBSERVACIONES : mdlDatos.mdlDocumento["0"]["Observaciones"],
          ID_DOCUMENTO_PADRE : mdlDatos.mdlDocumento["0"]["IdDocumentoPadre"],
          ID_TIPO_DOCUMENTO : mdlDatos.mdlDocumento["0"]["IdTipoDocumento"],
          ID_ENTIDAD : mdlDatos.mdlDocumento["0"]["IdEntidad"],
          ID_CONTRIBUYENTE : mdlDatos.mdlDocumento["0"]["IdContribuyente"],
          DOCUMENTO : mdlDatos.Documento_Bytes
        }

        return documentoCobro;
      }
      else
      {
        return null;
      }
    }catch (err)
    {
      this.handleError(err);
      return null
    }
  }

  /**
   * Metodo que realiza la consulta al web service para obtener el detalle de un servicio
   */
  async ObtieneDetalleServicio(servicio: ServicioDTO) {
    var Url = this.UrlApi + '/consultadetalleservicios';
    
    try {

      let headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
    
      let bodyParametros = {
        "ID_CONTRIBUYENTE_SERVICIO": servicio.ID_CONTRIBUYENTE_SERVICIO.toString()
      };

      const ApiResponse = await this.http.post(Url, bodyParametros, {observe: 'response'}).toPromise();

      //significa que todo salió bien, se devuelve el entero del error o el conjunto de datos
      if(ApiResponse.status == 200 &&  !isNullOrUndefined(ApiResponse.body))
      {
        let detalles = [];
        const mdlDatos = JSON.parse(ApiResponse["body"]["data"]); 
     
        for (var i=0; i < mdlDatos.mdlDetalleServicio.length; i++){
          let detalle: DetalleServicioDTO = {
            ID_CONTRIBUYENTE_SERVICIO: 0,
            DESCRIPCION: '',
            ID_DETALLE_SERVICIO: 0,
            ID_ENTIDAD: 0,
            ID_SERVICIO: 0,
            MONTO: 0,
            CUENTA_CORRIENTE: []
          }

          detalle.ID_CONTRIBUYENTE_SERVICIO = mdlDatos.mdlContribuyenteServicio[i]["IdContribuyenteServicio"];
          detalle.DESCRIPCION =  mdlDatos.mdlDetalleServicio[i]["Descripcion"]; 
          detalle.ID_DETALLE_SERVICIO = mdlDatos.mdlDetalleServicioEntidad[i]["IdDetalleServicio"];
          detalle.ID_ENTIDAD = mdlDatos.mdlDetalleServicioEntidad[i]["IdEntidad"]; 
          detalle.ID_SERVICIO = mdlDatos.mdlDetalleServicioEntidad[i]["IdServicio"]; 

          //solo se tiene que mandar a llamar una vez a la obtenida de la cuenta corriente
          if(i === 0){
            detalle.CUENTA_CORRIENTE = await this.ObtenerCuentaCorriente(detalle);
          }  

          detalle.MONTO = this.montoServicio;

          if (this.montoServicio>0) //Unicamente agrega el servicio si este cuenta con saldo
            detalles.push(detalle);
        };
      
        return detalles;  
      }      
    }catch (err)
    {
    this.handleError(err);
    }
  }

  /**
   * Metodo que obtiene la cuenta corriente de cada uno de los detalles del servicio
   */
  async ObtenerCuentaCorriente(detalle: DetalleServicioDTO){
    this.montoServicio = 0;
    var Url = this.UrlApi + '/consultacuentacorriente';
    
    try {
      let headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
    
      let bodyParametros = {
        "ID_SERVICIO": detalle.ID_SERVICIO.toString(), 
        "ID_ENTIDAD": detalle.ID_ENTIDAD.toString(), 
        "ID_DETALLE_SERVICIO": '0',
        "ID_CONTRIBUYENTE_SERVICIO" : detalle.ID_CONTRIBUYENTE_SERVICIO.toString()
      };

      const ApiResponse = await this.http.post(Url, bodyParametros, {observe: 'response'}).toPromise();

      //significa que todo salió bien, se arman los datos de la cuenta corrientes
      if(ApiResponse.status == 200 &&  !isNullOrUndefined(ApiResponse.body))
      {
        let cuentaCorriente = [];

        for (var i=0; i < ApiResponse.body["data"].length; i++){

          let item: CuentaCorrienteDTO = {
            ID_CONTRIBUYENTE_SERVICIO: 0,
            ID_CUENTA_CORRIENTE: 0,
            DESCRIPCION: '',
            MONTO: 0,
            FECHA_DEVENGADO:'',
            FECHA_MAX_PAGO: '' 
          }

          const fecha_devengado = new Date(ApiResponse.body["data"][i]["fechaDevengado"].toString());
          const fecha_max_pago = new Date(ApiResponse.body["data"][i]["fechaMaxPago"].toString());
          let dia_devengado;
          let dia_max_pago;
          let mes_devengado;
          let mes_max_pago;

          //ajustando el día y mes, se le agrega 0 al inicio si es menor a 10
          if(fecha_devengado.getDate() < 10)
            dia_devengado = "0" + fecha_devengado.getDate();
          else
            dia_devengado = fecha_devengado.getDate();

          if(fecha_max_pago.getDate() < 10)
            dia_max_pago = "0" + fecha_max_pago.getDate();
          else
            dia_max_pago = fecha_max_pago.getDate();

          if((fecha_devengado.getMonth() + 1) < 10)
            mes_devengado = "0" + (fecha_devengado.getMonth() + 1);
          else
            mes_devengado = (fecha_devengado.getMonth() + 1);

          if((fecha_max_pago.getMonth() + 1) < 10)
            mes_max_pago = "0" + (fecha_max_pago.getMonth() + 1);
          else
            mes_max_pago = (fecha_max_pago.getMonth() + 1);

          item.ID_CONTRIBUYENTE_SERVICIO = ApiResponse.body["data"][i]["idContribuyenteServicio"];
          item.ID_CUENTA_CORRIENTE = ApiResponse.body["data"][i]["idCuentaCorriente"];
          item.DESCRIPCION = ApiResponse.body["data"][i]["descripcion"];
          item.MONTO = parseFloat(ApiResponse.body["data"][i]["monto"]);
          item.FECHA_DEVENGADO = dia_devengado + "/" + mes_devengado + "/" + fecha_devengado.getFullYear();
          item.FECHA_MAX_PAGO = dia_max_pago + "/" + mes_max_pago + "/" + fecha_max_pago.getFullYear();

          this.montoServicio = this.montoServicio + item.MONTO;

          cuentaCorriente.push(item);
        }

        return cuentaCorriente;
      }
      else
        return null;
    
    }catch (err)
    {
      this.handleError(err);
    }
  }
  

  /**
   * Metodo que convierte el XML en objeto JSON
   * @param xml string en formato XML
   */
  parseXml(xml) {
    let json = {};
    parseString(xml, { explicitArray: false, ignoreAttrs: true }, (err, result) => {
      json = result;
    });
    return json;
  }


  /**
 * Metodo para el manejo de errores
 * @param error
 */
  handleError(err: any) {
    console.log(err);
    return Observable.throw(err);
  } 
}  

