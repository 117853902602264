/**
 * Función que ordena un objeto json que es arreglo
 * @param campo nombre del campo por el que se ordenara
 */
export function OrdenarPor(campo) {    
    return function(a, b) {    
        if (a[campo] > b[campo]) {    
            return 1;    
        } else if (a[campo] < b[campo]) {    
            return -1;    
        }    
        return 0;    
    }    
}    

/**
 * Función que ordena un objeto json que es arreglo
 * @param campo  nombre del campo por el que se ordenara
 * @param opcion A=Ascendente, D=Descendente
 */
export function OrdenarPorAscendenteDescendente(campo,opcion) {
    if (opcion == "A") {   
        return function(a, b) {    
            if (a[campo] > b[campo]) {    
                return 1;    
            } else if (a[campo] < b[campo]) {    
                return -1;    
            }    
            return 0;    
        }
    }
    if (opcion == "D") {   
        return function(a, b) {    
            if (a[campo] > b[campo]) {    
                return 1;    
            } else if (a[campo] < b[campo]) {    
                return -1;    
            }    
            return 0;    
        }
    }

} 

/**
 * Retorna los tipos de contriuyentes
 */
export function ObtenerTipoContribuyente() {
    var tipoContribuyente =  [
        {'id_tipo_contribuyente':1,'descripcion':'Contribuyente individual'},
        {'id_tipo_contribuyente':2,'descripcion':'Persona juridica'},
        {'id_tipo_contribuyente':3,'descripcion':'Contribuyente extranjero'},
    ]

    return tipoContribuyente;
}

/**
 * Retorn los departamentos 
 */
export function ObtenerDepartamentos() {
    var departamentos = [{"id_departamento":1,"nombre":"GUATEMALA"},
    {"id_departamento":2,	"nombre":"EL PROGRESO"},
    {"id_departamento":3,	"nombre":"SACATEPEQUEZ"},
    {"id_departamento":4,	"nombre":"CHIMALTENANGO"},
    {"id_departamento":5,	"nombre":"ESCUINTLA"},
    {"id_departamento":6,	"nombre":"SANTA ROSA"},
    {"id_departamento":7,	"nombre":"SOLOLA"},
    {"id_departamento":8,	"nombre":"TOTONICAPAN"},
    {"id_departamento":9,	"nombre":"QUETZALTENANGO"},
    {"id_departamento":10,	"nombre":"SUCHITEPEQUEZ"},
    {"id_departamento":11,	"nombre":"RETALHULEU"},
    {"id_departamento":12,	"nombre":"SAN MARCOS"},
    {"id_departamento":13,	"nombre":"HUEHUETENANGO"},
    {"id_departamento":14,	"nombre":"QUICHE"},
    {"id_departamento":15,	"nombre":"BAJA VERAPAZ"},
    {"id_departamento":16,	"nombre":"ALTA VERAPAZ"},
    {"id_departamento":17,	"nombre":"PETEN"},
    {"id_departamento":18,	"nombre":"IZABAL"},
    {"id_departamento":19,	"nombre":"ZACAPA"},
    {"id_departamento":20,	"nombre":"CHIQUIMULA"},
    {"id_departamento":21,	"nombre":"JALAPA"},
    {"id_departamento":22,	"nombre":"JUTIAPA"}];

    departamentos.sort(OrdenarPor("nombre"));

    return departamentos;
};


