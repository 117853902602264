/**
 * departamentos
 */
export class DepartamentoDTO {
    id_departamento: number;
    nombre: string;
}

/**
 * Datos básicos para realizar la consulta de servicios
 */
export class ParametrosConsultaDTO {
    id_contribuyente: number;
    identificacion: string;
    nombre: string;
    departamento: number;
    id_entidad: number;
    nim: string;
    tipoConsulta: number;
}

/**
 * La entidad realmente es el municipio
 */
export class EntidadDTO {
    id_entidad: number;
    nombre: string; 
    id_departamento: number;
}