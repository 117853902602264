import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ParametrosConsultaDTO, DepartamentoDTO, EntidadDTO } from './consultaDTO'
import { LocalStorageServiceEncrypt } from 'angular-2-local-storage-encrypt'
import { ContribuyenteDTO } from './../usuario/usuarioDTO'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConsultaService } from './consulta.service';
import { MensajeService } from 'src/app/Utilerias/mensajes/mensaje.service';
import { ConsultaServiciosService } from '../servicios/consulta-servicios.service';
import { ObtenerDepartamentos} from '../../Utilerias/arreglos';

@Component({
  selector: 'app-consulta',
  templateUrl: './consulta.component.html',
  styleUrls: ['./../servicios/consulta-servicios.component.scss'],
})
export class ConsultaComponent implements OnInit {
  submitted = false;
  formConsulta: FormGroup;
  departamentos: DepartamentoDTO[];
  contribuyente: ContribuyenteDTO;
  entidades: EntidadDTO[];
  consulta: ParametrosConsultaDTO;
  aplicaPagosEnLinea: boolean = false;
  cargando: boolean = false;

  @Input() id_entidad: number = 0;

  constructor(private router:Router, 
    private encryptLocalService: LocalStorageServiceEncrypt,
    private formBuilder: FormBuilder,
    private consultaService: ConsultaService,
    private mensajeService: MensajeService,
    private EntidadService: ConsultaServiciosService
    ) {  
      this.consulta = new ParametrosConsultaDTO();
    }

  ngOnInit() {
    this.cargando = false;
    this.cargaDatosContribuyente();
    this.CrearForm();
  }

  get f() { return this.formConsulta.controls; }

  /**
   * Metodo que crea el form de la página
   */
  CrearForm() {
    this.formConsulta = this.formBuilder.group(
      {
        id_contribuyente: [this.contribuyente.ID_CONTRIBUYENTE, [Validators.required]],
        usuario: [this.contribuyente.USUARIO,[Validators.required]],
        nombre_completo: [this.contribuyente.NOMBRE_COMPLETO,[Validators.required]],
        id_entidad: [0, [Validators.required, Validators.min(1)]],
        nim: [this.contribuyente.NIM,[Validators.required]],
        tipoConsulta: [0, [Validators.required]]
      }
    )
  }

 /** 
  * Metodo que recibe la entidad o municipalidad seleccionada
 * @param valor 
 */
   AsignaEntidad(id_entidad: number){
    this.formConsulta.patchValue({id_entidad: id_entidad});
  }


  /**
   * Metodo que ejecuta el llamado a los servicios enviando los parametros para obtener los servicios
   */
  async ObtenerServicios(){
    this.submitted = true;
      this.consulta.id_contribuyente = this.formConsulta.value.id_contribuyente;
      this.consulta.id_entidad = this.formConsulta.value.id_entidad;
      this.consulta.tipoConsulta = this.formConsulta.value.tipoConsulta;

      try {
        this.cargando = true;
        
        if (this.consulta.id_entidad>0){
          
          this.aplicaPagosEnLinea = await this.EntidadService.AplicaPagosEnLinea(this.consulta.id_entidad);

          this.encryptLocalService.set('parametrosConsulta', JSON.stringify (this.consulta));
          this.encryptLocalService.set('contribuyente', JSON.stringify (this.contribuyente));
          this.router.navigate(['/consulta-servicio'])
        }
      } catch (error) {
        this.mensajeService.Error("Ha habido un inconveniento al consultar los servicios");
      } finally
      {
        this.cargando = false;
      }
  }

  /**
   * Metodo que carga los datos del contribuyente al formualrio
   */
  cargaDatosContribuyente() {

    try {
      this.contribuyente = JSON.parse(this.encryptLocalService.get('contribuyente')); 
    } catch (error) {
      console.log("Metodo -> cargaDatosContribuyente, error: " + error);
    }
    
  }

  /**
   * 
   */
  acceder(){
    this.router.navigate(['/consulta-servicio'])
  }
}
