import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { LocalStorageServiceEncrypt } from 'angular-2-local-storage-encrypt';
import { ParametrosConsultaDTO } from '../consulta/consultaDTO';
import { ContribuyenteDTO } from '../usuario/usuarioDTO';
import { ConsultaServiciosService } from './consulta-servicios.service';
import { ContribuyenteServicioDTO, DocumentoCobroDTO, ServicioDTO, ParametrosPago, CatastroDTO, ParametrosDocumentoDeCobro, DetalleServicioDTO } from './consultaServicioDTO';
import { MatTableDataSource } from '@angular/material/table';
import { AbrirLinkExternoService } from '../../Utilerias/abrirLinkExterno.service';
import { MensajeService } from 'src/app/Utilerias/mensajes/mensaje.service';
import { map, reduce  } from 'rxjs/operators';
import { InitComponent } from '../init/init.component'
import { OrdenarPor, OrdenarPorAscendenteDescendente } from 'src/app/Utilerias/arreglos';
import { MatDialog } from '@angular/material';
import { VisorComponent } from '../visor/visor.component';
import { promise } from 'selenium-webdriver';
//import { GestionDeErroresService } from 'src/app/Utilerias/gestionDeErrores.service';

enum nivelDeCobro {
  contribuyente = 1,
  catastro,
  servicio,
}

enum reporte {
  contribuyente =  'GENERAR_DOCUMENTO_COBRO_CONTRIBUYENTE',
  catastro = 'GENERAR_DOCUMENTO_COBRO_CATASTRO',
  servicio = 'GENERAR_DOCUMENTO_COBRO_TARJETA'
}

@Component({
  selector: 'app-consulta-servicios',
  templateUrl: './consulta-servicios.component.html',
  styleUrls: ['./consulta-servicios.component.scss']
})
export class ConsultaServiciosComponent implements OnInit {
  parametroDocumentoCobro: ParametrosDocumentoDeCobro = new ParametrosDocumentoDeCobro();;
  formServicio: FormGroup;
  parametroConsulta: ParametrosConsultaDTO;
  contribuyente: ContribuyenteDTO;
  servicios: ServicioDTO[];
  servicio: ServicioDTO;
  columnasEncabezadoContribuyente: string[] = ['nim','numeroDocumento', 'nombre',  'totalDeuda'];
  columnasEncabezadoCatastro: string[] = ['ID_CATASTRO','CATASTRO','DIRECCION_SERVICIO','TOTAL_CATASTRO'];
  columnasServicios: string[] = ['ID_SERVICIO','IDENTIFICADOR', 'NOMBRE_SERVICIO', 'DIRECCION_SERVICIO','TOTAL_A_COBRAR'];
  columnasTotales: string[] = ['DIRECCION_SERVICIO','TOTAL_A_COBRAR'];
  datosContribuyente: ContribuyenteServicioDTO[]; //Se declara arreglo, ya que el control de la tabla solo acepta arreglo de datasource
  documentoCobro: DocumentoCobroDTO;  //Variable para almacenar el documento de cobro antes desplegar
  dataSourceContribuyente = new MatTableDataSource<any>();
  dataSourceServicios = new MatTableDataSource<any>();
  dataSourceCatastro = new MatTableDataSource<any>();
  linkPagos: string = 'https://serviciosgl.minfin.gob.gt/Integracion_Pago/frmCredencialesBoleto.aspx';//'https://serviciosgl.minfin.gob.gt/Integracion_BAC/frmCredencialesBAC.aspx';
  montoTotal: number = 0;
  aceptaPagosEnLinea: boolean = false;
  initComponent: InitComponent;
  catastros: CatastroDTO[];
  verTarjeta: boolean = false;
  mensajeBotonTarjeta: string = "Ver tarjeta(s)";
  cargando: boolean = false;
  seleccionoElementoAPagar: boolean = false;
  seleccionoServicio: boolean = false;
  HayCuentaCorriente: boolean = false;
  detalleServicio: DetalleServicioDTO[];
  
  @ViewChild(MatPaginator,{static: false}) paginator: MatPaginator;
  
  constructor(private router: Router,
    private consultaServiciosService: ConsultaServiciosService,
    private encryptLocalService: LocalStorageServiceEncrypt,
    private mensajeService: MensajeService,
    //private gestorErroresService: GestionDeErroresService,
    private abrirLinkExternoService: AbrirLinkExternoService,
    public dialog: MatDialog) {
  }


  /**
   * Metodoque carga lo necesario para la funcionalidad
   */
  ngOnInit() {
    this.seleccionoElementoAPagar = false;
    this.seleccionoServicio = false;
    this.parametroConsulta = new ParametrosConsultaDTO();

    this.ObtieneParametros();
    //this.AplicaPagosOnLine();
    this.CargaDatosContribuyente();
    this.ObtieneServiciosContribuyente();
    this.cargando = false;

  }

  /**
   * Metodo que valida si la municipalidad acepta pagos en linea
   */
  async AplicaPagosOnLine() {

    try {
      this.aceptaPagosEnLinea = await this.consultaServiciosService.AplicaPagosEnLinea(this.parametroConsulta.id_entidad);
      this.consultaServiciosService.aplicaPagosEnLinea$.emit(this.aceptaPagosEnLinea);
    }
    catch(err){
      this.mensajeService.Error("No fue posible verificar si la municipalidad acepta pagos en linea." + err);
    }
  }

  /**
   * Metodo que obtiene los servicios relacionados al contribuyente
   */
  async ObtieneServiciosContribuyente() {
    
    try {
      this.servicios = await this.consultaServiciosService.ObtenerServicios(this.parametroConsulta);
      this.CanculaMontoTotal();
      this.datosContribuyente[0].totalDeuda = this.montoTotal;
      this.dataSourceServicios.data = this.servicios;
      this.dataSourceServicios.paginator = this.paginator;
      this.catastros = this.AgrupaCatastros();
      this.dataSourceCatastro.data = this.catastros;
      this.dataSourceContribuyente.data = this.datosContribuyente;

    }
    catch(err){
      this.mensajeService.Error("No fue posible obtener los servicios relacionados al contribuyente");
    }
  }

  /**
   * Metodo que agrupa los catastros en base a los servicios y totaliza el totas de pago por cada catastro
   */
  AgrupaCatastros(): CatastroDTO[] {
    const c: CatastroDTO[] = [];

    try {
      let respuesta = this.servicios.map(servicio => {
        
        const cat = c.find(catastro => catastro.ID_CATASTRO === servicio.ID_CATASTRO);
  
        if (cat == undefined){
          let tmp = this.servicios.filter(s => s.ID_CATASTRO === servicio.ID_CATASTRO);

          let cat: CatastroDTO = {
            ID_CATASTRO : servicio.ID_CATASTRO,
            CATASTRO: servicio.CATASTRO,
            DIRECCION_SERVICIO : servicio.DIRECCION_SERVICIO,
            TOTAL_CATASTRO: tmp.map(c => c.TOTAL_A_COBRAR).reduce((valorPrevio, valorActual) => valorPrevio + valorActual, 0)
          };
          c.push(cat);
        }
      })

      c.sort(OrdenarPorAscendenteDescendente("CATASTRO","D"));

      return c;
      
    } catch (error) {
      this.mensajeService.Error("No fue posible mostrar catastro" + error);
    }
  }

  /**
   * Metodo que carga los datos del contribuyente para su despliegue en el HTML
   */
  CargaDatosContribuyente() {

    this.datosContribuyente = [{
      nim: this.contribuyente.NIM, 
      nombre: this.contribuyente.NOMBRE_COMPLETO, 
      numeroDocumento: this.contribuyente.USUARIO,
      totalDeuda: 0
    }]
  }

  /**
   * Metodo que lee los parametros del localstorge
   */
  ObtieneParametros() {
    try {
      this.parametroConsulta = JSON.parse(this.encryptLocalService.get('parametrosConsulta')); //Recuperamos los parametros de la consulta
      localStorage.removeItem('parametrosConsulta');
  
      this.contribuyente = JSON.parse(this.encryptLocalService.get('contribuyente')); //Recuperamos los datos del contribuyente
  
    } catch (error) {
      console.log("Metodo -> ObtieneParametros, Error: " + error);
    }
  }


  //TODO: 17122020 - Hay que esperar a ver que resuelven para poder generar el pago por cada servicio, arbitrio o impuesto
  /**
   * Metodo para la gestión de generar el documento de pago
   */
  async GenerarDocumentoDeCobro(){
    let id_contribuyente: number;
    let reporte: string;
  
    try {
      this.cargando = true;
      this.parametroDocumentoCobro.id_entidad = this.parametroConsulta.id_entidad;
      this.parametroDocumentoCobro.strUsr = '';
      this.parametroDocumentoCobro.strPwd = '';
      this.parametroDocumentoCobro.SessionUsuario = '';

      this.documentoCobro = await this.consultaServiciosService.ValidaExistenciaDocumentoCobro(this.parametroDocumentoCobro);

      if (this.documentoCobro == null){
        this.mensajeService.Informacion("El servicio no tienen saldo, por lo que no se generará documento de pago");
        return false;
      }else
      {
        this.EnviaReporteAVisor(this.documentoCobro);
        this.documentoCobro.DOCUMENTO = null;  //Quitamos el documento de cobro para liberar memoria
        this.encryptLocalService.set('documentoCobro', JSON.stringify (this.documentoCobro));
      }      
    }
    catch(error){
      console.log("Metodo -> ObtieneParametros, Error: " + error);
    }
    finally
    {
      this.cargando = false;
    }
  }

  /**
   * Abre la interfaz para ver el documento generado en una ventana modal
   * @param documento documento a visualizar
   */
  public EnviaReporteAVisor(documento:DocumentoCobroDTO, muestraServicios: boolean = true) {

    const dialogRef = this.dialog.open(VisorComponent, {
      width: '80%',
      data:  JSON.stringify(documento),
      height: '90%'
    });

    if (muestraServicios){
      dialogRef.afterClosed().subscribe(result => {
        this.ObtieneServiciosContribuyente();
      });
    }
  }

  /**
   * Metodo que abre la interfaz para el pago de los servicios
   */
  RealizarPagoDocumentoCobro(){
    this.documentoCobro = JSON.parse(this.encryptLocalService.get('documentoCobro')); //Recuperamos los parametros de la consulta
    localStorage.removeItem('documentoCobro');

    try {
      //var param = {'id_entidad':'33', 'no_documento':'5451812', 'monto_documento':'104.81','id_contribuyente':'82991', 'contribuyente':'LETICIA MARQUEZ ROCHE MADRID'};

      if (this.documentoCobro.MONTO_DOCUMENTO > 0){
        let parametrosPago: ParametrosPago = {
          id_entidad: this.documentoCobro.ID_ENTIDAD,
          no_documento: this.documentoCobro.NUMERO_DOCUMENTO,
          monto_documento: this.documentoCobro.MONTO_DOCUMENTO,
          id_contribuyente: this.documentoCobro.ID_CONTRIBUYENTE,
          contribuyente: this.datosContribuyente[0].nombre,
          correo: this.contribuyente.CORREO_ELECTRONICO
        }

        this.abrirLinkExternoService.post(parametrosPago, this.linkPagos);
      }
      else
      {
        this.mensajeService.Advertencia("No existe información para realizar el cobro");
      }
      
    } catch (error) {
      this.mensajeService.Error("Se ha producido un inconveniente para realizar el pago, por favor intente mas tarde");
    }
    
  }

  /**
   * Función que suma el monto total a pagar por todos los servicios
   */
  CanculaMontoTotal() {
    this.montoTotal = this.servicios.map(s => s.TOTAL_A_COBRAR).reduce((valorPrevio, valorActual) => valorPrevio + valorActual, 0);
    
    if (this.montoTotal > 0)
      this.HayCuentaCorriente = true;
    else  {
      //this.mensajeService.Exito("El contribuyente NO tiene cuenta corriente o saldo pendiente actualmente.");
      this.HayCuentaCorriente = false;
    }
  }


  /**
   * Metodo que retorna a la interfaz de selección de entidad
   */
  regresar(){
    this.router.navigate(['/consulta'])
  }

  
  /**
   * Selección de un contribuyente
   * @param seleccion Metodo que obtiene los datos del item seleccionado
   */
  SeleccionaElementoContribuyente(seleccion: ContribuyenteDTO){
    this.parametroDocumentoCobro.id_contribuyente = this.contribuyente.ID_CONTRIBUYENTE;
    this.parametroDocumentoCobro.id_contribuyente_doc = this.contribuyente.ID_CONTRIBUYENTE;
    this.parametroDocumentoCobro.reporte = reporte.contribuyente;
    this.seleccionoElementoAPagar = true;
    this.seleccionoServicio = false;
    this.servicio = null;
  }

  /**
   * Selección de un catastro
   * @param seleccion Metodo que obtiene los datos del item seleccionado
   */
  SeleccionaElementoCatastro(seleccion: CatastroDTO){
    this.parametroDocumentoCobro.id_contribuyente = seleccion.ID_CATASTRO;
    this.parametroDocumentoCobro.id_contribuyente_doc = this.contribuyente.ID_CONTRIBUYENTE;
    this.parametroDocumentoCobro.reporte = reporte.catastro;
    this.dataSourceServicios.data = this.servicios.filter(s => s.ID_CATASTRO === seleccion.ID_CATASTRO);
    this.montoTotal = this.dataSourceServicios.data.map(s => s.TOTAL_A_COBRAR).reduce((valorPrevio, valorActual) => valorPrevio + valorActual, 0);
    this.seleccionoElementoAPagar = true;
    this.seleccionoServicio = false;
    this.servicio = null;

    this.VerTarjetas();
  }


  /**
   * Selección de un servicio
   * @param seleccion Metodo que obtiene los datos del item seleccionado
   */
  SeleccionaElementoServicio(seleccion: ServicioDTO){
    this.parametroDocumentoCobro.id_contribuyente = seleccion.ID_SERVICIO_CATASTRO;
    this.parametroDocumentoCobro.id_contribuyente_doc = this.contribuyente.ID_CONTRIBUYENTE;
    this.parametroDocumentoCobro.reporte = reporte.servicio;
    this.seleccionoElementoAPagar = true;
    this.seleccionoServicio = true;
    this.servicio = seleccion;
  }

  
  /**
   * Abre la interfaz para ver el detalle del servicio
   * @param servicio
   */
  async VerDetalle(){
    this.servicio.ID_ENTIDAD = this.parametroConsulta.id_entidad;
    localStorage.removeItem('servicioSeleccionado');
    this.detalleServicio = await this.ObtieneDetalleServicio();
    this.encryptLocalService.set('cuentaCorriente', JSON.stringify(this.detalleServicio[0].CUENTA_CORRIENTE));
    //this.router.navigate(['/detalle-servicio']);
    this.router.navigate(['/cuenta-corriente']);
  }

  /**
   * Metodo que obtiene los servicios relacionados al contribuyente
   */
   async ObtieneDetalleServicio(): Promise<DetalleServicioDTO[]> {
     
    try {
      this.cargando = true;

      return await this.consultaServiciosService.ObtieneDetalleServicio(this.servicio);
    }
    catch(error){
      console.log("Metodo -> ObtieneParametros, Error: " + error);
    } finally {
      this.cargando = false;
    }
  }


  /**
   * Metodo que actualiza dinamicamente el ver las tarjetas
   */
  VerTarjetas(){
    this.verTarjeta = !this.verTarjeta;

    if (this.verTarjeta)
      this.mensajeBotonTarjeta = "Ocultar tarjeta(s)";
    else
      this.mensajeBotonTarjeta = "Ver tarjeta(s)";
  }
}
