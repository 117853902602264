import { EventEmitter, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { parseString } from 'xml2js';
import { promise } from 'protractor';
import { parseBooleans } from 'xml2js/lib/processors';
import { UsuarioDTO } from '../usuarioDTO';
import { isNullOrUndefined } from "util";

@Injectable()
export class CrearUsuarioService {
    private UrlSOAP = "comunicabanco/comunicabanco.asmx?op=";
    private UrlApi = "Coregl/api/comunicabanco";   //URL para QA y otros ambientes
    //private UrlApi = "api/comunicabanco";   //URL para desa
    usuario: string = 'PORTALGL';
    clave: string = 'SECRETO';
    montoServicio: number = 0;
    aplicaPagosEnLinea$ = new EventEmitter<boolean>();

    private headers = new HttpHeaders({
        'Content-Type': 'text/xml;charset=UTF-8'
    });

    /**
   * Declaramos esta variable que contiene el esquema del XML para realizar el POST.
   */
    private httpBodyCrearUsuario = `<?xml version="1.0" encoding="utf-8"?>
    <soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">
      <soap12:Body>
        <CrearSolicitud_Contribuyente xmlns="http://tempuri.org/">
          <intID_Departamento>|id_departamento|</intID_Departamento>
          <intID_Entidad>|id_entidad|</intID_Entidad>
          <intID_Tipo_Contribuyente>|id_tipo_contribuyente|</intID_Tipo_Contribuyente>
          <strDocumentoIdentificacion>|identificacion|</strDocumentoIdentificacion>
          <strNombreArchivo>|nombreArchivo|</strNombreArchivo>
          <btDocumento>|archivo|</btDocumento>
          <strNombres>|nombres|</strNombres>
          <strApellidos>|apellidos|</strApellidos>
          <strCorreoElectronico>|correoElectronico|</strCorreoElectronico>
          <strDireccionCorrespondencia>|direccionCorrespondencia|</strDireccionCorrespondencia>
          <intTelefono>|telefono|</intTelefono>
        </CrearSolicitud_Contribuyente>
      </soap12:Body>
    </soap12:Envelope>`;

    private httpBodyValidarContribuyente_Entidad = `<?xml version="1.0" encoding="utf-8"?>
    <soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">
      <soap12:Body>
        <ValidarContribuyente_Entidad xmlns="http://tempuri.org/">
          <intId_Entidad>|id_entidad|</intId_Entidad>
          <intTipoContribuyente>|id_tipo_contribuyente|</intTipoContribuyente>
          <strDocumentoIdentificacion>|identificacion|</strDocumentoIdentificacion>
        </ValidarContribuyente_Entidad>
      </soap12:Body>
    </soap12:Envelope>`;

    /**
   * Definimos todo lo necesario para las consultas
   * @param http
   */
    constructor(private http: HttpClient) {}

    /**
   * Consume el ws SOAP para solicitar usuario
   * @param credenciales contiene los datos del contribuyente
   */
  SolicitaCreacionDeUsuario(usuario: UsuarioDTO) {
    var xmlhttp = new XMLHttpRequest();
    var Url = this.UrlSOAP + 'CrearSolicitud_Contribuyente';
    var datos;
    var respuesta;

    let bodyParametros = this.httpBodyCrearUsuario.replace('|id_departamento|',usuario.ID_DEPARTAMENTO.toString());
    bodyParametros = bodyParametros.replace('|id_entidad|',usuario.ID_ENTIDAD.toString());
    bodyParametros =  bodyParametros.replace('|id_tipo_contribuyente|',usuario.ID_TIPO_CONTRIBUYENTE.toString());
    bodyParametros =  bodyParametros.replace('|identificacion|',usuario.DOCUMENTO_IDENTIFICACION);
    bodyParametros =  bodyParametros.replace('|nombreArchivo|',usuario.NOMBRE_ARCHIVO);
    bodyParametros =  bodyParametros.replace('|archivo|',usuario.DOCUMENTO);
    bodyParametros =  bodyParametros.replace('|nombres|',usuario.NOMBRE);
    bodyParametros =  bodyParametros.replace('|apellidos|',usuario.APELLIDOS);
    bodyParametros =  bodyParametros.replace('|correoElectronico|',usuario.CORREO_ELECTRONICO);
    bodyParametros =  bodyParametros.replace('|direccionCorrespondencia|',usuario.DIRECCION_CORRESPONDENCIA);
    bodyParametros =  bodyParametros.replace('|telefono|',usuario.TELEFONO);
   
    try {
      xmlhttp.open('POST', Url, false);
      xmlhttp.onreadystatechange = function (data) {
            if (xmlhttp.readyState == 4) {
              if (xmlhttp.status == 200) {
                datos = xmlhttp.responseXML;
              }
            }}

      xmlhttp.setRequestHeader('Content-Type', 'text/xml');
      xmlhttp.send(bodyParametros);

      respuesta = datos.getElementsByTagName("CrearSolicitud_ContribuyenteResult")[0].textContent;
      return respuesta;

    }
    catch (err)
    {
      this.handleError(err);
    }
  }

  /**
   * Consume el ws SOAP para solicitar usuario
   * @param credenciales contiene los datos del contribuyente
   */
    async ValidaContribuyenteEntidad(usuario: UsuarioDTO) {
      var xmlhttp = new XMLHttpRequest();
      //var Url = this.UrlSOAP + 'ValidarContribuyente_Entidad';
      var Url = this.UrlApi + '/validacontribuyente_entidad';
      var mensaje = "";
          
      try {
        let headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');

        const ApiResponse = await this.http.post(Url, usuario, {observe: 'response'}).toPromise();

        //significa que todo salió bien, caso contrario que devuelva objeto nulo
        if(ApiResponse.status == 200 &&  !isNullOrUndefined(ApiResponse.body))
              mensaje = ApiResponse.body.toString();
        else 
              mensaje = "Error al tratar de comunicarse con el servidor"

        return mensaje;
  
      }
      catch (err)
      {
        this.handleError(err);
      }
  }

  /**
   * Metodo que realiza la consulta al web services para generar el documento de cobro
   */
   async SolicitaCreacionDeUsuarioAPI(usuario: UsuarioDTO): Promise<String> {
    var Url = this.UrlApi + '/creasolicitudcontribuyente';
    var mensaje = "";

    try {
      let headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');

      usuario.NOMBRE_ARCHIVO = usuario.NOMBRE_ARCHIVO.replace(/\\/g, "/");

      const ApiResponse = await this.http.post(Url, usuario, {observe: 'response'}).toPromise();

      //significa que todo salió bien, caso contrario que devuelva objeto nulo
      if(ApiResponse.status == 200 &&  !isNullOrUndefined(ApiResponse.body))
        mensaje = "OK"
      else 
        mensaje = "Not"

      return mensaje;

    }catch (err)
    {
      this.handleError(err);
      return null
    }
  }

  

  /**
   * Metodo para el manejo de errores
   * @param error
   */
  handleError(error: string) {
    console.log(error);
    return Observable.throw(error);
  }

}