import { DateAdapter } from '@angular/material';

/**
 * Interfaz para la gestión de los datos del usuario
 */
export interface UsuarioDTO_Anterior {
  id_departamento: number;
  id_entidad: number;
  id_tipo_contribuyente: number;
  identificacion: string;
  nombreArchivo: string;
  archivo: string;
  nombres: string;
  apellidos: string;
  correoElectronico: string;
  direccionCorrespondencia: string;
  telefono: string;
}

export interface UsuarioDTO  {
  ID_DEPARTAMENTO : number,
  ID_ENTIDAD : number,
  ID_TIPO_CONTRIBUYENTE :number,
  DOCUMENTO_IDENTIFICACION : string,
  NOMBRE_ARCHIVO :string,
  DOCUMENTO : string,
  NOMBRE : string,
  APELLIDOS : string,
  CORREO_ELECTRONICO : string,	
  DIRECCION_CORRESPONDENCIA : string,
  TELEFONO : string
}

/**
 * INterfaz para la gestión de los datos del login
 */
export class LoginDTO {
  usuario: string;
  clave: string;
  tipoContribuyente: string;
  nueva_clave: string;
  confirmacion_clave: string;
  id_contribuyente: number;
}
/* 
export class ContribuyenteDTO {

  id_contribuyente: number;
  id_tipo_contribuyente: number;
  estado: string;
  nombre: string;
  numero_telefono_alterno: string;
  numero_telefono: string;
  nim: string;
  direccion_domicilio: string;
  dpi: string;
  zona_notificacion: string;
  barrio_colonia_notificacion: string;
  direccion_notificacion: string;
  zona_domicilio: string;
  barrio_colonia_domicilio: string;
  correo_electronico: string;
  nit: string;
  fecha_nacimiento: string;
  cedula: string;
  nombre_responsable: string;
  apellido_paterno: string;
  apellido_materno: string;
  apellido_casada: string;
  no_pasaporte: string;
  calle_domicilio: string;
  avenida_domicilio: string;
  numero_exterior: string;
  numero_interior: string;
  calle_notificacion: string;
  avenida_notificacion: string;
  numero_exterior_ntf: string;
  numero_interior_ntf: string;
  municipio_domicilio: number;
  departamento_domicilio: number;
  municipio_notificacion: number;
  departamento_notificacion: number;
  descripcion: string;
  nombre_completo: string;
  id_entidad: number;
  representante_legal: string;
  fallecido: string;
  pago_online: string;
  password: string;
  usuario_activacion_pago_online: string;
  fecha_activacion_pago_online: Date;
  usuario_ultima_actualizacion: string;
  fecha_ultima_actualizacion: Date;
  por_validar: string;
  primer_ingreso: string;
  usuario: string;
  } */

  
/** 
 * Interfaz para la gestión de los datos del contribuyente 
*/
export interface ContribuyenteDTO {
USUARIO: string;
ID_CONTRIBUYENTE:number;
ID_TIPO_CONTRIBUYENTE:number;
ESTADO:string;
NOMBRE:string;
NIM:string;
DPI:number;
BARRIO_COLONIA_NOTIFICACION:string;
BARRIO_COLONIA_DOMICILIO:string;
CORREO_ELECTRONICO:string;
NIT:number;
APELLIDO_PATERNO: string;
APELLIDO_MATERNO:string;
APELLIDO_CASADA:string;
MUNICIPIO_DOMICILIO:number;
DEPARTAMENTO_DOMICILIO:number;
MUNICIPIO_NOTIFICACION:number;
DEPARTAMENTO_NOTIFICACION:number;
DESCRIPCION:string;
NOMBRE_COMPLETO:string;
ID_ENTIDAD:number;
REPRESENTANTE_LEGAL:string;
FALLECIDO:string;
PAGO_ONLINE:string;
PASSWORD:string;
FECHA_ACTIVACION_PAGO_ONLINE:Date;
POR_VALIDAR:string;
PRIMER_INGRESO:string;
}

/**
 * interfaz para los datos del tipo de contribuyente
 */
export interface TipoContribuyenteDTO {
  id_tipo_contribuyente: Number;
  descripcion: string
}

