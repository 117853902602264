import { FormControl, FormGroup, Validator } from '@angular/forms';

// Valida que dos campos sean iguales
export function ValidaIgualdad(nombreControlUno: string, nombreControlDos: string) {
    return (formGroup: FormGroup) => {
        const controlUno = formGroup.controls[nombreControlUno];
        const controlDos = formGroup.controls[nombreControlDos];

        // Si tiene otro error el campo a compara retorma para resolver ese campoi
        if (controlDos.errors && !controlDos.errors.ValidaIgualdad) {
            return;
        }

        // Setea que existe error si no coinciden los campos
        if (controlUno.value !== controlDos.value) {
            controlDos.setErrors({ ValidaIgualdad: true });
        } else {
            controlDos.setErrors(null);
        }
    }
}

export enum tipoDocumento {
    dpi = 1,
    nit = 2,
    pasaporte = 3,
}

export enum tipoContribuyente {
    individual = 1,
    juridico = 2,
    extranjero = 3,
}

/**
 * Función que valida que el número ingresado sea un DPI
 * @param dpi 
 */
export function ValidaDPI(dpi: string){
    var largo = dpi.length;

    if (dpi.length == 13) {
        //se verifica que el DPI sea numerico
        var tmp=parseInt(dpi);
    
        if (isNaN(tmp))
            return tipoContribuyente.extranjero;//'El nit debe ser numérico';
        else
        {
            if (tmp.toString().length != largo)
                return tipoContribuyente.extranjero;//'El nit debe ser numérico';
        }
        return tipoContribuyente.individual;
    }
    else
        return tipoContribuyente.extranjero;
}

/**
 * Función que retorna el tipo de contribuyente en base al número ingresado
 * @param numeroDocumento número de documento con que identifico el usuario
 */
export function IdentificarTipoContribuyente(numeroDocumento){
    var largo;
    var indice;
    var digito;
    var digitov;
    var suma;
    var diferencia;
    var valor1;
    var valor2;
    
    // se verifica que exista un nit a validar
    if (numeroDocumento.length <= 0)
        return 0;
    
    //se verifica que el nit no traiga guion
    if (numeroDocumento.search('-') != -1)
        return ValidaDPI(numeroDocumento);

    //se verifica longitud del nit
    if (numeroDocumento.length < 4 || numeroDocumento.length > 12) {
        return ValidaDPI(numeroDocumento);
    }
       
    //se separa el nit y el digito verificador
    digitov=numeroDocumento.substr(numeroDocumento.length-1,1);
    numeroDocumento =numeroDocumento.substr(0,numeroDocumento.length-1);
    largo=numeroDocumento.length;
    indice=largo;
    suma=0;
    
    //se verifica que el nit sea numerico
    var tmp=parseInt(numeroDocumento);
    if (isNaN(tmp))
        return ValidaDPI(numeroDocumento);
        //'El nit debe ser numérico';
    else
    {
        if (tmp.toString().length != largo)
            return ValidaDPI(numeroDocumento);
            ;//'El nit debe ser numérico';
    }
    
    //se verifica que el verificador sea numerico o 'K'
    if (isNaN(parseInt(digitov))){      
        digitov = digitov.toUpperCase();
            
        if (digitov != 'K')
            return ValidaDPI(numeroDocumento);
            ;//'Nit inv&aacute;lido';
    }
       
    //se verifica que el nit no sea ni 0 ó 99 ya que son genericos y no existen
    var numt = parseInt(numeroDocumento);
    
    if ((numt == 0) || (numt == 99))
        return ValidaDPI(numeroDocumento);
        ;//'Nit inv&aacute;lido';
                     
    while (indice<=largo && indice>=1)
    {
        valor1=largo-indice+2;
        valor2=parseInt(numeroDocumento.substr(indice-1,1));
        suma=suma+valor2*valor1;
        indice=indice-1;
    }

       const residuo=suma % 11;
       diferencia=11-residuo;

       if (diferencia==10 )
              digito='K';
       else
       {
            if (diferencia==11)
                digito='0';
            else
                digito=diferencia.toString();
       }

        if (digito == digitov)
            return tipoContribuyente.juridico;  //'2 = ';
        else 
            return ValidaDPI(numeroDocumento);//'Nit inv&aacute;lido';       
}  
