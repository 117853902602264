import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-visor',
  templateUrl: './visor-recibo.component.html',
  styleUrls: ['./visor-recibo.component.scss']
})
export class VisorReciboComponent implements OnInit {
  public src: Blob;
  public titulo: string = "Recibo de pago"; //TODO: Hay que cambiarlo por el parametro enviado para el docomento que se muestre
  aceptaPagosEnLinea: boolean = false;
  

  constructor(private router:Router,
    private dialogRef: MatDialogRef<VisorReciboComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
    ) { }

  ngOnInit() {
    this.DesplegarDocumentoDeCobroEnPDF();
  }

  regresar(){
    this.router.navigate(['/consulta-servicios']);
  }

  /**
   * Metodo que lee los parametros del localstorge
   */
 async DesplegarDocumentoDeCobroEnPDF() {
    const base64Response = await fetch(`data:application/pdf;base64,${this.data}`);
    this.src = await base64Response.blob();
  }

  
  /**
   * Cerrar dialogo
   */
  Cerrar(){
    this.dialogRef.close();
  }
}
