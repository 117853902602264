import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ParametrosConsultaDTO, DepartamentoDTO, EntidadDTO } from '../../consulta/consultaDTO'
import { LocalStorageServiceEncrypt } from 'angular-2-local-storage-encrypt'
import { TipoContribuyenteDTO, UsuarioDTO } from './../usuarioDTO'
import { Form, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ConsultaService } from '../../consulta/consulta.service';
import { ObtenerDepartamentos, ObtenerTipoContribuyente } from 'src/app/Utilerias/arreglos';
import { MensajeService } from 'src/app/Utilerias/mensajes/mensaje.service';
import { ValidaIgualdad } from 'src/app/Utilerias/Validadores';
import { CrearUsuarioService } from './crear-usuario.service';
import * as _ from 'lodash';
import { ModeloArchivo } from './modelo-archivo';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-crearUsuario',
  templateUrl: './crear-usuario.component.html',
  styleUrls: ['./crear-usuario.component.scss']
})
export class CrearUsuarioComponent implements OnInit {
  //@ViewChild('formulario',{ static: true}) formUsuario: any;
  submitted = false;
  formCrearUsuario: FormGroup;
  departamentos: DepartamentoDTO[];
  tiposContribuyente: TipoContribuyenteDTO[];
  entidades: EntidadDTO[];
  consulta: ParametrosConsultaDTO;
  contenidoArchivo: string;
  nombreArchivo: string;
  fileInformation: any;
  private archivos: Array<ModeloArchivo> = [];
  cargando: boolean = false;

  @Input() id_entidad: number = 0;
  
  constructor(private router:Router, 
    private encryptLocalService: LocalStorageServiceEncrypt,
    private formBuilder: FormBuilder,
    private mensajeService: MensajeService,
    private consultaService: ConsultaService,
    private creaUsuarioService: CrearUsuarioService,
    private _http: HttpClient
    //private gestorErrores: GestionDeErroresService
    ) {  }

  ngOnInit() {
    this.consulta = new ParametrosConsultaDTO();
    //this.departamentos = ObtenerDepartamentos();
    this.tiposContribuyente = ObtenerTipoContribuyente();
    this.CreaForm();
    this.cargando = false;
  }

  get f() { return this.formCrearUsuario.controls; }

  /**
   * Metodo que crea el form de la página
   */
  CreaForm() {
    this.formCrearUsuario = this.formBuilder.group(
      {
        id_departamento: [0, [Validators.required, Validators.min(1)]],
        id_entidad: [0, [Validators.required, Validators.min(1)]],
        id_tipo_contribuyente: [0, [Validators.required, Validators.min(1)]],
        identificacion: ['',[Validators.required]],
        nombreArchivo: ['', Validators.required],
        archivo: [''],
        nombres: ['',Validators.required],
        apellidos: ['',Validators.required],
        correoElectronico: ['',[Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
        confirmacionCorreo: ['', [Validators.required]],
        direccionCorrespondencia: ['',[Validators.required]],
        telefono: ['',[Validators.required]]
      }, 
      {
        validator: ValidaIgualdad('correoElectronico', 'confirmacionCorreo')
      }
    )
  }

  /**
   * Metodo que llama al servicio para obtener las entidades
   * @param id_departamento identificador del departamento para el que se requieren las entidades
   */
  /* async ObtenerEntidades(id_departamento:number){
    this.consulta.departamento = id_departamento;
  
    try {
      this.entidades = await this.consultaService.ObtenerEntidades(id_departamento);
      this.entidades.sort();
    }
    catch(err){
      this.mensajeService.Error("No fue posible obtener los municipios del departamento");
    }
  } */

  /**
   * Metodo que recibe la entidad o municipalidad seleccionada
   * @param valor 
   */
   AsignaEntidad(id_entidad: number){
    this.formCrearUsuario.patchValue({id_entidad: id_entidad});
  }

  /**
   * Metodo que recibe el departamento seleccionado
   * @param id_departamento 
   */
  AsignaDepartamento(id_departamento: number){
    this.formCrearUsuario.patchValue({id_departamento: id_departamento});
  }

  /**
   * Retorna a la interfaz principal
   */
  Cancelar(){
    this.router.navigate(["/"])
  }


  /**
   * Metodo que ejecuta el llamado a los servicios enviando los parametros para obtener los servicios 
   */
  async SolicitarUsuario(){
    this.submitted = true;
    var resultado;
    var documento = this.archivos.toString().split(",");
    
    if (this.formCrearUsuario.valid){
      const solicitudUsuario: UsuarioDTO = {
        ID_DEPARTAMENTO : +this.formCrearUsuario.value.id_departamento,
        ID_ENTIDAD : +this.formCrearUsuario.value.id_entidad,
        ID_TIPO_CONTRIBUYENTE : +this.formCrearUsuario.value.id_tipo_contribuyente,
        DOCUMENTO_IDENTIFICACION : this.formCrearUsuario.value.identificacion,
        NOMBRE_ARCHIVO : this.formCrearUsuario.value.nombreArchivo,
        DOCUMENTO :  documento[1],
        NOMBRE : this.formCrearUsuario.value.nombres,
        APELLIDOS : this.formCrearUsuario.value.apellidos,
        CORREO_ELECTRONICO : this.formCrearUsuario.value.correoElectronico,
        DIRECCION_CORRESPONDENCIA : this.formCrearUsuario.value.direccionCorrespondencia,
        TELEFONO : this.formCrearUsuario.value.telefono
      }
      
      try {
        this.cargando = true;
        
        let esContribuyente = await this.creaUsuarioService.ValidaContribuyenteEntidad(solicitudUsuario);

        if ( esContribuyente == "OK"){
          resultado = await this.creaUsuarioService.SolicitaCreacionDeUsuarioAPI(solicitudUsuario);

          if (resultado === "OK"){
            this.mensajeService.Exito("La solicitud se realizo con exito");
            this.ResetFormulario(this.formCrearUsuario);
          }
          else
            this.mensajeService.Advertencia(resultado);

        } else
          this.mensajeService.Informacion(esContribuyente);

      } catch (error) {
        this.mensajeService.Error("No fue posible realizar la solicitud de usuario, por favor intente mas tarde");
      } finally {
        this.cargando = false;
      }
    }
    else
      this.mensajeService.Advertencia('Todos los campos son obligatorios');
  }

  /**
   * Metodo que inicializa el formulario
   * @param formulario 
   */
  ResetFormulario(formulario:FormGroup) {
    Object.keys(formulario.controls).forEach((key) => {
        const control = formulario.controls[key];
        control.reset();
        control.clearValidators();
        control.updateValueAndValidity();
    });
  }

  
  /**
   * 
   * @param event metodo que carga el archivo del contribuyente
   */
  CargaArchivo(event){

    if(event.target.files){
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event:any) =>
        this.archivos = event.target.result;
        this.nombreArchivo = event.target.value;
        this.formCrearUsuario.patchValue({ nombreArchivo: event.target.value});
      }
    }
}
