import { Component, OnInit, ViewChild } from '@angular/core';
import { Form, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatPaginator, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { LocalStorageServiceEncrypt } from 'angular-2-local-storage-encrypt';
import { MensajeService } from 'src/app/Utilerias/mensajes/mensaje.service';
import { DetalleReciboComponent } from './detalle-recibo/detalle-recibo.component';
import { DetalleReciboDTO } from './detalleReciboDTO';
import { ReciboElectronicoService } from './recibo-electronico.service';

@Component({
  selector: 'app-verificar-recibo',
  templateUrl: './verificar-recibo.component.html',
  styleUrls: ['./verificar-recibo.component.scss'],
})
export class VerificarReciboComponent implements OnInit {
  recibo : DetalleReciboDTO;
  dataSource = new MatTableDataSource<any>();
  formFirma: FormGroup;
  cargando: boolean = false;

  @ViewChild(MatPaginator,{static: false}) paginator: MatPaginator;

  constructor(private router: Router,
    private reciboElectronicoService: ReciboElectronicoService,
    private mensajeService: MensajeService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private encryptLocalService: LocalStorageServiceEncrypt) { }

  ngOnInit() {
    this.cargando = false;
    this.CrearForm();
  }

  /**
   * Metodo que crea la forma para presetar los datos
   */
  CrearForm() {
    this.formFirma = this.fb.group(
      {
        firmaElectronica: ['']
      }
    )
  }

  /**
   * Abre la interfaz para ver el recibo de pago
   * @param detalleRecibo los datos del detalle del recibo de pago
   */
  async VerificarRecibo() {
    
    try {
      this.cargando = true;

      this.recibo = await this.reciboElectronicoService.ConsultaReciboElectronico(this.formFirma.value.firmaElectronica);

      if(this.recibo != null){

        this.encryptLocalService.set('recibo', JSON.stringify(this.recibo));
        localStorage.setItem("paginaVerificaRecibo", this.router.url);
        this.router.navigate(['/detalle-recibo']);

      }else {
        //console.log("El recibo: " + this.formFirma.value.firmaElectronica + " no tiene cuenta corriente.");
        this.mensajeService.Advertencia("El recibo ingresado no existe.");
      }
    }
    catch(err){
      this.mensajeService.Error('No fue posible consultar el recibo ' + this.formFirma.value.firmaElectronica);
    } finally {
      this.cargando = false;
    }
  }

  Cancelar(){
    this.router.navigate([localStorage.getItem("paginaAnterior")])
  }
}
