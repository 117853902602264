import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject} from "rxjs";
import { HttpClient, HttpHeaders} from "@angular/common/http";
import { LoginDTO } from "./../usuarioDTO";
import { isNullOrUndefined } from "util";

@Injectable()
export class RecuperarClaveService {
  private usuarioSubject: BehaviorSubject<LoginDTO>;
  public usuario: Observable<LoginDTO>;
  private UrlSOAP = "comunicabanco/comunicabanco.asmx?op=";

  private UrlApi = "Coregl/api/comunicabanco";   //URL para QA y otros ambientes
  //private UrlApi = "api/comunicabanco";   //URL para desa

  private RecuperacionClave = `<?xml version="1.0" encoding="utf-8"?>
  <soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">
    <soap12:Body>
      <RecuperarClaveContribuyente xmlns="http://tempuri.org/">
        <strUsuario>|USUARIO|</strUsuario>
        <intTipoContribuyente>|TIPO_CONTRIBUYENTE|</intTipoContribuyente>
      </RecuperarClaveContribuyente>
    </soap12:Body>
  </soap12:Envelope>`;


  private headers = new HttpHeaders({
    'Content-Type': 'text/xml;charset=UTF-8'
  });

  /**
   * Definimos todo lo necesario para
   * @param http
   */
  constructor(private http: HttpClient) {}


  /**
   * Realiza la consulta de usuario a la base de datos
   * @param credenciales contiene los datos del usuario (usuario, password)
   */
  async RecuperarClave(credenciales: LoginDTO) {
    var respuesta;

    var Url = this.UrlApi + '/recuperaclavecontribuyente';

    //encabezado del api con coregl
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
  
    let bodyParametros = {
      "STR_USR": credenciales.usuario, 
      "ID_TIPO_CONTRIBUYENTE" : credenciales.tipoContribuyente
    };

    try {
      const ApiResponse = await this.http.post(Url, bodyParametros, {observe: 'response'}).toPromise();

      if(ApiResponse.status == 200 &&  !isNullOrUndefined(ApiResponse.body))
      {
        respuesta = ApiResponse.body["mensaje"];
      }
     
      return respuesta;
    }
    catch (err)
	  {
      this.handleError(err);
	  }
  }


  /**
   * Metodo para el manejo de errores
   * @param error
   */
  handleError(error: string) {
    console.log(error);
    return Observable.throw(error);
  }

  /**
   * Solicita el token para que trabaje el usuario
   * */
  ObtenerToken(): string {
    return localStorage.getItem("token");
  }

  /**
   * * Obtiene la fecha y hora de la expiración
   * */
  ObtenerExpiracionToken(): string {
    return localStorage.getItem("expiracionToken");
  }
}