import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-visor-mapa',
  templateUrl: './visor-mapa.component.html',
  styleUrls: ['./visor-mapa.component.scss']
})
export class VisorMapaComponent implements OnInit {
  lat;
  lng;

  constructor(private dialogRef: MatDialogRef<VisorMapaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,) { }

  ngOnInit() {
    this.Armarlocation();
  }

  /**
   * Metodo que arma las coordenadas de geolocalización de los puntos de cobro
   */
   Armarlocation() {
    var geolocation = this.data["UBICACION_MAPA"].toString().split(" ");
    this.lat =  parseFloat(geolocation[0]);
    this.lng = parseFloat(geolocation[1]);
  }

  /**
   * Cerrar dialogo
   */
   Cerrar(){
    this.dialogRef.close();
  }

}
