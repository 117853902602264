import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageServiceEncrypt } from 'angular-2-local-storage-encrypt';
import { ConsultaServiciosService } from '../consulta-servicios.service';
import { ContribuyenteDTO } from '../../usuario/usuarioDTO';
import { CuentaCorrienteDTO, DetalleServicioDTO, ServicioDTO } from '../consultaServicioDTO';
import { MatDialog } from '@angular/material';
import { CuentaCorrienteComponent } from '../cuenta-corriente/cuenta-corriente.component';
import { MensajeService } from 'src/app/Utilerias/mensajes/mensaje.service';
 
@Component({
  selector: 'app-detalle-servicio',
  templateUrl: './detalle-servicio.component.html',
  styleUrls: ['./../consulta-servicios.component.scss']
})
export class DetalleServicioComponent implements OnInit {
  contribuyente: ContribuyenteDTO;
  servicioSeleccionado: ServicioDTO;
  detalleServicio: DetalleServicioDTO[];
  datosEncabezado: ServicioDTO[];
  cuentaCorriente: CuentaCorrienteDTO[];
  verCuentaCorrienteHabilitado: boolean = false;
  cargando: boolean = false;

  columnasEncabezado: string[] = ['NOMBRE_SERVICIO', 'IDENTIFICADOR', 'CATASTRO', 'DIRECCION_SERVICIO', 'TOTAL_A_COBRAR'];
  columnasDetalle: string[] = [ 'opciones','DESCRIPCION', 'MONTO'];

   constructor(private router: Router,
    private consultaServiciosService: ConsultaServiciosService,
    private encryptLocalService: LocalStorageServiceEncrypt,
    private mensajeService: MensajeService,
    public dialog: MatDialog) {
  }

  /**
   * Metodoque carga lo necesario para la funcionalidad
   */
  ngOnInit() {
    this.cargando = false;
    this.ObtieneParametros();
    this.ObtieneDetalleServicio();
  }


  /**
   * Metodo que obtiene los servicios relacionados al contribuyente
   */
  async ObtieneDetalleServicio() {
    try {
      this.cargando = true;

      this.detalleServicio = await this.consultaServiciosService.ObtieneDetalleServicio(this.servicioSeleccionado);
    }
    catch(error){
      console.log("Metodo -> ObtieneParametros, Error: " + error);
    } finally {
      this.cargando = false;
    }
  }

    /**
   * Metodo que lee los parametros del localstorge
   */
  ObtieneParametros() {
    this.servicioSeleccionado = JSON.parse(this.encryptLocalService.get('servicioSeleccionado')); //Recuperamos los datos del servicio seleccionado
    this.datosEncabezado = [this.servicioSeleccionado]; //Cargamos los datos para desplegar el encabezado
    localStorage.removeItem('parametrosConsulta');
  }


  /**
   * Abre la interfaz para ver la cuenta corriente del servicio
   * @param detalleServicio los datos del detalle del servicio
   */
  VerCuentaCorriente(): void {
    
    if(this.cuentaCorriente){
      this.cargando = true;
      this.encryptLocalService.set('cuentaCorriente', JSON.stringify(this.cuentaCorriente));
      this.router.navigate(['/cuenta-corriente']);
      this.cargando = false;
    }else
      this.mensajeService.Advertencia("Debe seccionar un servicios para ver su cuenta corriente");
  }

  /**
   * Abre la interfaz para ver la interfaz de pagos
   * @param servicio
   */
  VerInterfazDePagos(detalleServicio: DetalleServicioDTO): void {
    let cuentaCorriente: CuentaCorrienteDTO[] = detalleServicio.CUENTA_CORRIENTE;

    localStorage.removeItem('cuentaCorriente');
    this.encryptLocalService.set('cuentaCorriente', JSON.stringify(cuentaCorriente));

    const dialogRef = this.dialog.open(CuentaCorrienteComponent, {
      width: '80%',
      data: cuentaCorriente
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('El dialogo se ha cerrado');
    });
  }

  SeleccionaRubro(detalleServicio: DetalleServicioDTO){
    this.cuentaCorriente = detalleServicio.CUENTA_CORRIENTE;
    this.verCuentaCorrienteHabilitado = true;
  }

  /**
   * Metodo que retorna el control al listado de servicios del usuario
   */
  regresar(){
    this.router.navigate(['/consulta-servicio'])
  }

}
