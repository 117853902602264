import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ConsultaServiciosService } from '../servicios/consulta-servicios.service';

@Component({
  selector: 'app-init',
  templateUrl: './init.component.html',
  styleUrls: ['./init.component.scss']
})
export class InitComponent implements OnInit {
  mobile: boolean
  cerrarSesion: boolean
  cambiarClave: boolean
  verificarRecibo: boolean
  regresar: boolean
  pagarBoletoDeOrnato: boolean
  ultimaRuta: string
  rutaActual: string
  historialPagos: boolean
  pagoDocumentoCobro: boolean
  verificarMunicipalidad: boolean
  pagarMultasTransito : boolean

  constructor(private router: Router,
    public consultaService: ConsultaServiciosService ) { 
    this.mobile = false;
    this.cerrarSesion = false;
    this.cambiarClave = false;
    this.verificarRecibo = false;
    this.pagarBoletoDeOrnato = false;
    this.historialPagos = false;
    this.ultimaRuta = '/';
    this.rutaActual = "/";

    if (window.screen.width <= 590)
      this.mobile = true;

    router.events.subscribe((val) => {
      if(val instanceof NavigationEnd){
        this.ultimaRuta = this.rutaActual

        this.rutaActual = val.url
        switch(val.url){
          case "/":
            this.cerrarSesion = false
            this.cambiarClave = false
            this.verificarRecibo = true
            this.pagarBoletoDeOrnato = true
            this.historialPagos = false
            this.pagoDocumentoCobro = true
            this.verificarMunicipalidad = true
            this.pagarMultasTransito = true
          break

          case "/consulta":
            this.cerrarSesion = true
            this.cambiarClave = true
            this.verificarRecibo = true
            this.pagarBoletoDeOrnato = true
            this.historialPagos = true
            this.pagoDocumentoCobro = false
            this.verificarMunicipalidad = false
            this.pagarMultasTransito = true
          break

          case "/consulta-servicio":
            this.cerrarSesion = true
            this.cambiarClave = false
            this.verificarRecibo = false
            this.pagarBoletoDeOrnato = true
            this.historialPagos = true
            this.pagoDocumentoCobro = false
            this.verificarMunicipalidad = false
            this.pagarMultasTransito = true
          break

          case "/detalle-servicio":
            this.cerrarSesion = true
            this.cambiarClave = false
            this.verificarRecibo = false
            this.pagarBoletoDeOrnato = false
            this.historialPagos = true
            this.pagoDocumentoCobro = false
            this.verificarMunicipalidad = false
            this.pagarMultasTransito = true
          break

          case "/login":
            this.cerrarSesion = false
            this.cambiarClave = false
            this.verificarRecibo = false
            this.pagarBoletoDeOrnato = true
            this.historialPagos = false
            this.pagoDocumentoCobro = false
            this.verificarMunicipalidad = true
            this.pagarMultasTransito = false
          break

          case "/cambio_clave":
            this.cerrarSesion = true
            this.cambiarClave = false
            this.verificarRecibo = true
            this.pagarBoletoDeOrnato = true
            this.historialPagos = true
            this.pagoDocumentoCobro = false
            this.verificarMunicipalidad = false
            this.pagarMultasTransito = true
          break

          case "/pago":
            this.cerrarSesion = true
            this.cambiarClave = false
            this.verificarRecibo = false
            this.pagarBoletoDeOrnato = true
            this.historialPagos = true
            this.pagoDocumentoCobro = false
            this.verificarMunicipalidad = false
            this.pagarMultasTransito = false
          break

          case "/verificar-recibo":
            this.cerrarSesion = false
            this.cambiarClave = false
            this.verificarRecibo = false
            this.pagarBoletoDeOrnato = false
            this.historialPagos = false
            this.pagoDocumentoCobro = false
            this.verificarMunicipalidad = false
            this.pagarMultasTransito = false
          break

          case "/visor":
            this.cerrarSesion = true
            this.cambiarClave = false
            this.verificarRecibo = false
            this.pagarBoletoDeOrnato = false
            this.regresar = true
            this.historialPagos = false
            this.pagoDocumentoCobro = false
            this.verificarMunicipalidad = false
            this.pagarMultasTransito = false
          break

          case "/crear-usuario":
            this.cerrarSesion = false
            this.cambiarClave = false
            this.verificarRecibo = false
            this.pagarBoletoDeOrnato = false
            this.historialPagos = false
            this.pagoDocumentoCobro = false
            this.verificarMunicipalidad = false
            this.pagarMultasTransito = false
          break

          case "/pago-boleto":
            this.cerrarSesion = false
            this.cambiarClave = false
            this.verificarRecibo = false
            this.pagarBoletoDeOrnato = false
            this.historialPagos = false
            this.pagoDocumentoCobro = false
            this.verificarMunicipalidad = false
            this.pagarMultasTransito = false
          break

          case "/historial-pagos":
            this.cerrarSesion = true
            this.cambiarClave = true
            this.verificarRecibo = true
            this.pagarBoletoDeOrnato = true
            this.historialPagos = false
            this.pagoDocumentoCobro = false
            this.verificarMunicipalidad = false
            this.pagarMultasTransito = true
          break
        }

        if(val.url != '/visor'){
          this.regresar = false
        }
      }
    });
  }

  Cerrar(){
    this.router.navigate(['/'])
  }

  CambiarClave(){
    localStorage.setItem("paginaAnterior", this.router.url);
    this.router.navigate(['/cambio-clave'])
  }

  VerificarRecibo(){
    localStorage.setItem("paginaAnterior", this.router.url);
    this.router.navigate(['/verificar-recibo'])
  }

  PagarBoletoDeOrnato(){
    localStorage.setItem("paginaAnterior", this.router.url);
    this.router.navigate(['/pago-boleto'])
  }

  HistorialPagos(){
    localStorage.setItem("paginaAnterior", this.router.url);
    this.router.navigate(['/historial-pagos'])
  }

  PagarDocumentoCobro(){
    localStorage.setItem("paginaAnterior", this.router.url);
    this.router.navigate(['/pago-documento-cobro'])
  }

  DesplegarInformacionMunicipalidad(){
    localStorage.setItem("paginaAnterior", this.router.url);
    this.router.navigate(['/informacion-municipalidad'])
  }

  PagarMultasTransito(){
    localStorage.setItem("paginaAnterior", this.router.url);
    this.router.navigate(['/consulta-multa-transito'])
  }

  ngOnInit() {
    
  }

}
