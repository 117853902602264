/**
 * Contiene los datos del servicio
 */
export interface ServicioDTO {
  ID_CONTRIBUYENTE_SERVICIO: number;
  ID_SERVICIO: number;
  NOMBRE_SERVICIO: string;
  IDENTIFICADOR: string;
  ID_CATASTRO: number;
  CATASTRO: string;
  DIRECCION_SERVICIO: string;
  TOTAL_A_COBRAR: number;
  ID_ENTIDAD: number;
  ID_SERVICIO_CATASTRO: number;
}

/**
 * Se utiliza para el despliegue de los datos básicos del contribuyente en las interfaces
 */
export interface ContribuyenteServicioDTO {
  nim: string;
  nombre: string;
  numeroDocumento: string;
  totalDeuda: number;
}

/**
 * Parametros utilizados para obtener todos los servicios asociados que tiene un contribuyente con la municipalidad
 */
export interface parametrosConsulta {
  id_contribuyente: string;
  id_entidad: number;
  tipoConsulta: number;
}

/**
 * Parametros utilizados para realizar la generación del documento de cobro
 */
export class ParametrosDocumentoDeCobro {
  strUsr: string;
  strPwd: string;
  reporte: string;
  id_contribuyente: number;
  id_entidad: number;
  SessionUsuario: string;
  cuentas_corrientes: string;
  id_contribuyente_doc : number;
}

/**
 * Estructura para el desglose de como esta compuesta la cuenta de un servicio
 */
export interface DetalleServicioDTO {
  ID_SERVICIO: number;
  ID_ENTIDAD: number;
  ID_DETALLE_SERVICIO: number;
  DESCRIPCION: string;
  ID_CONTRIBUYENTE_SERVICIO: number;
  MONTO: number;
  CUENTA_CORRIENTE: CuentaCorrienteDTO[];
}

/**
 * Datos de la cuenta corriente de un servicio
 */
export interface CuentaCorrienteDTO {
  ID_CONTRIBUYENTE_SERVICIO: number;
  ID_CUENTA_CORRIENTE: number;
  DESCRIPCION: string;
  MONTO:number;
  FECHA_DEVENGADO:string;
  FECHA_MAX_PAGO:string;
}

/**
 * Datos que se obtienen con el documento de cobro
 */
export interface DocumentoCobroDTO {
  ID_DOCUMENTO: number;
  NUMERO_DOCUMENTO: number;
  DESCRIPCION: string;
  FECHA_DOCUMENTO: Date;
  MONTO_DOCUMENTO: number;
  ESTADO: string;
  ID_PERSONA: string;
  FECHA_APROBADO: Date;
  FECHA_REFERENCIA: Date;
  OBSERVACIONES: string;
  ID_DOCUMENTO_PADRE: number;
  ID_TIPO_DOCUMENTO: number;
  ID_ENTIDAD: number;
  ID_CONTRIBUYENTE: number;
  DOCUMENTO: Blob;
}

/**
 * Parametros utilizados para realizar el pago de servicios
 */
export interface ParametrosPago {
  id_entidad: number;
  no_documento: number;
  monto_documento: number;
  id_contribuyente: number;
  contribuyente: string;
  correo: string;
}

/**
 * Inrterface utilizado para 
 */
export interface CatastroDTO {
  ID_CATASTRO: number;
  CATASTRO: string;
  DIRECCION_SERVICIO: string;
  TOTAL_CATASTRO: number;
}

/**
 * 
 */
export interface ParametrosSeleccionDeCobro {
 identificador: number;
 reporte: string;
}