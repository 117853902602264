import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { LocalStorageServiceEncrypt } from 'angular-2-local-storage-encrypt';
import { CuentaCorrienteDTO } from '../consultaServicioDTO';
import { MatTableDataSource } from '@angular/material';
import { DocumentoCobroDTO, ParametrosDocumentoDeCobro } from '../consultaServicioDTO';
import { ConsultaServiciosService } from '../consulta-servicios.service';
import { VisorComponent } from '../../visor/visor.component';
import { MensajeService } from 'src/app/Utilerias/mensajes/mensaje.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { ContribuyenteDTO } from '../../usuario/usuarioDTO';
import { ParametrosConsultaDTO } from '../../consulta/consultaDTO';

enum reporte {
  contribuyente =  'GENERAR_DOCUMENTO_COBRO_CONTRIBUYENTE',
  catastro = 'GENERAR_DOCUMENTO_COBRO_CATASTRO',
  servicio = 'GENERAR_DOCUMENTO_COBRO_TARJETA',
  cuenta_corriente = 'GENERAR_DOCUMENTO_COBRO_CUENTA_CORRIENTE'
}

@Component({
  selector: 'app-cuenta-corriente',
  templateUrl: './cuenta-corriente.component.html',
  styleUrls: ['./../consulta-servicios.component.scss']
})
export class CuentaCorrienteComponent implements OnInit {
  cargando: boolean = false;
  documentoCobro: DocumentoCobroDTO;  //Variable para almacenar el documento de cobro antes desplegar
  parametroDocumentoCobro: ParametrosDocumentoDeCobro = new ParametrosDocumentoDeCobro();
  cuentaCorriente: CuentaCorrienteDTO[];
  contribuyente: ContribuyenteDTO;
  columnasCuentaCorriente: string[] = ['ID_CUENTA_CORRIENTE','DESCRIPCION', 'MONTO', 'FECHA_DEVENGADO', 'FECHA_MAX_PAGO'];
  seleccionoElementoAPagar: boolean = false;
  seleccionoCuenta_Corriente: boolean = false;
  Monto_Total = 0;
  Seleccionado = false;
  hayCuentaCorriente: boolean = false;
  dataSourceCuentaCorriente = null;
  parametroConsulta: ParametrosConsultaDTO;

  checkedIDs = [];
  cuentas_corrientes = [];
  indices_checked = [];

  @ViewChild(MatPaginator,{static: false}) paginator: MatPaginator;

  constructor(private router: Router,
    private encryptLocalService: LocalStorageServiceEncrypt,
    private mensajeService: MensajeService,
    public dialog: MatDialog, 
    private consultaServiciosService: ConsultaServiciosService)
  {

  }
  
  ngOnInit() {
    this.hayCuentaCorriente = false;
    this.cargando = false;

    this.parametroConsulta = new ParametrosConsultaDTO();

    this.ObtieneParametros();
    this.CargaLosDatosALaTabla();
  }


  /**
   * Metodo que carga los datos a la tabla HTML
   */
  CargaLosDatosALaTabla() {
    
    this.dataSourceCuentaCorriente = new MatTableDataSource<CuentaCorrienteDTO>(this.cuentaCorriente);
    this.dataSourceCuentaCorriente.paginator = this.paginator;
    this.hayCuentaCorriente = true;
  }

  /**
   * Metodo que lee los parametros del localstorge
   */
  ObtieneParametros() {
    this.cuentaCorriente = JSON.parse(this.encryptLocalService.get('cuentaCorriente')); //Recuperamos la cuenta corriente del servicio

    var i = 0;
    for (i=0; i< this.cuentaCorriente.length; i++){
      this.cuentas_corrientes.push(this.cuentaCorriente[i].ID_CUENTA_CORRIENTE)
    }

    localStorage.removeItem('cuentaCorriente');

    this.parametroConsulta = JSON.parse(this.encryptLocalService.get('parametrosConsulta')); //Recuperamos los parametros de la consulta
      localStorage.removeItem('parametrosConsulta');
  }

  /**
   * Metodo que retorna el control al listado de detalles de servicio
   */
  regresar(){
    this.router.navigate(['/consulta-servicio'])
  }

  /**
   * Selección de un servicio
   * @param seleccion Metodo que obtiene los datos del item seleccionado
   */
  cambiaSeleccion(seleccion: CuentaCorrienteDTO, event){
    if(event.checked == true){ 
      this.seleccionoElementoAPagar = true;
      
      this.checkedIDs.push(seleccion.ID_CUENTA_CORRIENTE);
      this.indices_checked.push(this.cuentas_corrientes.indexOf(seleccion.ID_CUENTA_CORRIENTE));

      this.Monto_Total = this.Monto_Total + seleccion.MONTO    
    }
    else
    {
      //si se ha deseleccionado entonces se quita del arreglo buscandolo por el indice
      const posicion: number = this.checkedIDs.indexOf(seleccion.ID_CUENTA_CORRIENTE);
      this.checkedIDs.splice(posicion, 1);
      this.indices_checked.splice(posicion, 1);

      this.Monto_Total = this.Monto_Total - seleccion.MONTO;

      if (this.Monto_Total == 0)
        this.seleccionoElementoAPagar = false;
    }
  }

  ValidarGenerarDocumentoDeCobro()
  {
    //se revisa el orden de las cuentas corrientes seleccionadas.
    var no_generar = false;

    for (let posicion = 0; posicion < this.checkedIDs.length; posicion++) {
      //pueden venir en desorden por lo tanto hay que buscar una por una
      if (!this.indices_checked.includes(posicion))
      {
        no_generar = true;
        break;
      }
    }

    if (no_generar)
    {
      this.mensajeService.Error("Debe de seleccionar los registros en orden cronológico, con base a la fecha máxima de pago.");
    }
    else
    {
      //se manda a generar el documento de cobro con las cuentas corrientes seleccionadas
      this.contribuyente = JSON.parse(this.encryptLocalService.get('contribuyente'));
      this.parametroDocumentoCobro.id_contribuyente = this.contribuyente.ID_CONTRIBUYENTE;
      this.parametroDocumentoCobro.id_contribuyente_doc = this.contribuyente.ID_CONTRIBUYENTE;
      this.parametroDocumentoCobro.reporte = reporte.cuenta_corriente;
      this.seleccionoCuenta_Corriente = true;

      //se arma el string de cuentas corrientes
      var string_cuentas: string;
      for (let cuenta = 0; cuenta < this.checkedIDs.length; cuenta++) {
        if(cuenta == 0)
        {
          string_cuentas = this.checkedIDs[0].toString();
        }
        else
        {
          string_cuentas = string_cuentas + ',' + this.checkedIDs[cuenta].toString();
        }
      }

      this.parametroDocumentoCobro.cuentas_corrientes = string_cuentas;

      //se manda a generar el documento de cobro
      this.GenerarDocumentoDeCobro();
    }
  }

  //TODO: 02/03/2021 - gespinales
  /**
   * Metodo para la gestión de generar el documento de pago
   */
  async GenerarDocumentoDeCobro(){
    try {
      this.cargando = true;
      this.parametroDocumentoCobro.id_entidad = this.parametroConsulta.id_entidad;
      this.parametroDocumentoCobro.strUsr = '';
      this.parametroDocumentoCobro.strPwd = '';
      this.parametroDocumentoCobro.SessionUsuario = '';
      
      this.documentoCobro = await this.consultaServiciosService.ValidaExistenciaDocumentoCobro(this.parametroDocumentoCobro);
      
      if (this.documentoCobro == null){
        this.mensajeService.Informacion("Ha ocurrido un error interno y no se ha podido generar el documento de cobro");
        return false;
      }else
      {
        this.EnviaReporteAVisor(this.documentoCobro);
        this.documentoCobro.DOCUMENTO = null;  //Quitamos el documento de cobro para liberar memoria
        this.encryptLocalService.set('documentoCobro', JSON.stringify (this.documentoCobro));
      }      
    }
    catch(error){
      console.log("Metodo -> ObtieneParametros, Error: " + error);
    }
    finally
    {
      this.cargando = false;
    }
  }

  /**
   * Abre la interfaz para ver el documento generado en una ventana modal
   * @param documento documento a visualizar
   */
  EnviaReporteAVisor(documento:DocumentoCobroDTO) {

    const dialogRef = this.dialog.open(VisorComponent, {
      width: '80%',
      data:  JSON.stringify(documento),
      height: '90%'
    });

    /*dialogRef.afterClosed().subscribe(result => {
      this.ObtieneServiciosContribuyente();
    });*/
  }
}
