import { Component, Input } from '@angular/core';
import { MensajeComponent } from './Utilerias/mensajes/mensaje.component'

@Component({
  selector: 'app-root',
  //templateUrl: './app.component.html',
  template: `<app-init></app-init>
             <mensaje [id]="id"></mensaje>
             <div style="width:10%"></div>
             <router-outlet style="width:60%, align:center"></router-outlet>
             <div style="width:10%"></div>`,
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Pagos GL';
  @Input() id: string;
}

//<div>
//<div style="width:10%"></div>
