import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject} from "rxjs";
import { HttpClient, HttpHeaders} from "@angular/common/http";
import { LoginDTO, ContribuyenteDTO } from "./../usuarioDTO";
import { parseStringPromise } from 'xml2js';
import { parseString } from 'xml2js';
import { isNullOrUndefined } from "util";

const soapRequest = require('easy-soap-request');

@Injectable()
export class LoginService {
  private usuarioSubject: BehaviorSubject<LoginDTO>;
  public usuario: Observable<LoginDTO>;
  private UrlSOAP = "comunicabanco/comunicabanco.asmx?op=";
  contribuyente: ContribuyenteDTO;

  private UrlApi = "Coregl/api/comunicabanco";   //URL para QA y otros ambientes
  //private UrlApi = "api/comunicabanco";   //URL para desa

  private httpBodyAutenticacion = `<?xml version="1.0" encoding="utf-8"?>
  <soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">
    <soap12:Body>
      <ConsultaDatosContribuyente xmlns="http://tempuri.org/">
        <strUsuario>|USUARIO|</strUsuario>
        <intTipoContribuyente>|TIPO_CONTRIBUYENTE|</intTipoContribuyente>
      </ConsultaDatosContribuyente>
    </soap12:Body>
  </soap12:Envelope>`;

  private Autenticacion = `<?xml version="1.0" encoding="utf-8"?>
  <soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">
    <soap12:Body>
      <LoginPortalPagos xmlns="http://tempuri.org/">
        <strUsuario>|USUARIO|</strUsuario>
        <strClave>|CLAVE|</strClave>
        <intTipoContribuyente>|TIPO_CONTRIBUYENTE|</intTipoContribuyente>
      </LoginPortalPagos>
    </soap12:Body>
  </soap12:Envelope>`;

  private DatosContibuyente = `<?xml version="1.0" encoding="utf-8"?>
  <soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">
    <soap12:Body>
      <ConsultaDatosContribuyente xmlns="http://tempuri.org/">
        <strUsuario>|USUARIO|</strUsuario>
        <intTipoContribuyente>|TIPO_CONTRIBUYENTE|</intTipoContribuyente>
      </ConsultaDatosContribuyente>
    </soap12:Body>
  </soap12:Envelope>`;

  private RecuperacionClave = `<?xml version="1.0" encoding="utf-8"?>
  <soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">
    <soap12:Body>
      <RecuperarClaveContribuyente xmlns="http://tempuri.org/">
        <strUsuario>|USUARIO|</strUsuario>
        <intTipoContribuyente>|TIPO_CONTRIBUYENTE|</intTipoContribuyente>
      </RecuperarClaveContribuyente>
    </soap12:Body>
  </soap12:Envelope>`;


  private headers = new HttpHeaders({
    'Content-Type': 'text/xml;charset=UTF-8'
  });

  /**
   * Definimos todo lo necesario para
   * @param http
   */
  constructor(private http: HttpClient) {}

  /**
   * Realiza la consulta de usuario a la base de datos
   * @param credenciales contiene los datos del usuario (usuario, password)
   */
   async login(credenciales: LoginDTO) {
    var datos;
    var Url = this.UrlApi + '/loginportalpagos';
    
    try {
      //encabezado del api con coregl
      let headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
    
      let bodyParametros = {
        "STR_USR": credenciales.usuario.toString(), 
        "STRPWD" : credenciales.clave.toString(),
        "ID_TIPO_CONTRIBUYENTE" : credenciales.tipoContribuyente.toString()
      };

      const ApiResponse = await this.http.post(Url, bodyParametros, {observe: 'response'}).toPromise();

      //significa que todo salió bien, se devuelve el entero del error o el conjunto de datos
      if(ApiResponse.status == 200 &&  !isNullOrUndefined(ApiResponse.body))
      {
        if(ApiResponse["body"]["data"] instanceof Array){
          //trae data entonces se devuelve la información del contribuyente de una vez        
          datos = ApiResponse["body"]["data"]; 
        }
        else
        {
          //se devuelve el error de login
          datos = ApiResponse["body"]["mensaje"]; 
        }
      }
      else
      {
        datos = ApiResponse["body"]["mensaje"];
      }
      
      return datos;   
    }
    catch (err)
	  {
      this.handleError(err);
	  }
  }

  /**
   * Realiza la consulta para obtener los datos del contribuyente
   * @param identificador es el id en un string
   */
  /*ObtenerContribuyente(credenciales: LoginDTO) {
    var xmlhttp = new XMLHttpRequest();
    var Url = this.UrlSOAP + 'ConsultaDatosContribuyente';
    var datos, resultado;
    
    let bodyParametros = this.DatosContibuyente.replace('|USUARIO|',credenciales.usuario);
    bodyParametros =  bodyParametros.replace('|TIPO_CONTRIBUYENTE|',credenciales.tipoContribuyente);

    try {
      xmlhttp.open('POST', Url, false);
      xmlhttp.onreadystatechange = function (data) {
            if (xmlhttp.readyState == 4) {
                if (xmlhttp.status == 200) {
                  datos = xmlhttp.responseXML;
                }
              }
            }
            
      xmlhttp.setRequestHeader('Content-Type', 'text/xml');
      xmlhttp.send(bodyParametros);

      resultado = this.parseXml(datos.getElementsByTagName("ConsultaDatosContribuyenteResult")[0].childNodes[1].childNodes[0].innerHTML);
      this.contribuyente = resultado.Table;
    
      return this.contribuyente;
      
    }
    catch (err)
	  {
      this.handleError(err);
	  }
  }*/

  /**
   * Metodo que convierte el XML en objeto JSON
   * @param xml string en formato XML
   */
  parseXml(xml) {
    let json = {};
    parseString(xml, { explicitArray: false, ignoreAttrs: true }, (err, result) => {
      json = result;
    });
    return json;
  }

 
  /**
   * Metodo que transforma el XML de respuesta 
   */
  async TransformarEnJson(xml: string){
    return await parseStringPromise(xml,  { explicitArray: false })
        .then((result:string) => {
          return result;
        })
        .catch(error => console.log(error));
  }


  /**
   * Metodo para el manejo de errores
   * @param error
   */
  handleError(error: string) {
    console.log(error);
    return Observable.throw(error);
  }

  

  /**
   * Solicita el token para que trabaje el usuario
   * */
  ObtenerToken(): string {
    return localStorage.getItem("token");
  }

  /**
   * * Obtiene la fecha y hora de la expiración
   * */
  ObtenerExpiracionToken(): string {
    return localStorage.getItem("expiracionToken");
  }

  /**
   * Gestiona la finalización de la sesión
   * */
  Logout() {
    localStorage.removeItem('Autenticado');
  }

  /**
   * Verifica si el usuario esta en sesión
   * */
  EstaLogueado(): boolean {
    var exp = this.ObtenerExpiracionToken();
    var now = new Date().getTime();
    var dateExp = new Date(exp);

    if (!exp) {
      return false;
    }

    if (now >= dateExp.getTime()) {
      // ya expiró el token
      localStorage.removeItem("token");
      localStorage.removeItem("expiracionToken");
      return false;
    } else {
      return true;
    }
  }
}
