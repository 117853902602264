import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalStorageServiceEncrypt } from 'angular-2-local-storage-encrypt';
// import { GestionDeErroresService } from 'src/app/Utilerias/gestionDeErrores.service';
import { MensajeService } from 'src/app/Utilerias/mensajes/mensaje.service';
import { ValidaIgualdad } from 'src/app/Utilerias/Validadores';

import { ContribuyenteDTO, LoginDTO } from '../usuarioDTO';
import { CambioClaveService } from './cambio-clave.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DatosDialogo, DialogoAvisoComponent } from 'src/app/Utilerias/dialogo-aviso/dialogo-aviso.component';


@Component({
  selector: 'app-cambio-clave',
  templateUrl: './cambio-clave.component.html',
  styleUrls: ['./../../servicios/consulta-servicios.component.scss'],
})
export class CambioClaveComponent implements OnInit {
  submitted = false;
  formCambioClave: FormGroup;
  clavePattern = '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}';
  cargando = false;
  contribuyente: ContribuyenteDTO;
  login: LoginDTO;
  id_contribuyente: number;
  respuestaDialogo: string;

  constructor(private router:Router,
    private cambioClaveService: CambioClaveService,
    private formBuilder: FormBuilder,
    private encryptLocalService: LocalStorageServiceEncrypt,
    private mensajeService: MensajeService,
    public dialog: MatDialog
    ) { }

  ngOnInit() {
    this.login = new LoginDTO();
    this.CargaDatosContribuyente();
    this.CrearForm();
  }

  /**
   * Metodo que carga los datos del contribuyentes para obtener el id_contribuyente
   */
  CargaDatosContribuyente() {
    this.contribuyente = JSON.parse(this.encryptLocalService.get('contribuyente')); //Recuperamos los datos del contribuyente
    this.id_contribuyente = this.contribuyente.ID_CONTRIBUYENTE;
  }

  get f() {return this.formCambioClave.controls;}

  /**
   * Metodo que crea el forma para el cambio de clave
   */
  CrearForm() {
    this.formCambioClave = this.formBuilder.group({
      id_contribuyente: [this.id_contribuyente,[Validators.required, Validators.min(1)]],
      clave: ['', [Validators.required]],
      nueva_clave: ['', [Validators.required, Validators.pattern(this.clavePattern), Validators.min(8)]],
      confirmacion_clave: ['', [Validators.required, Validators.pattern(this.clavePattern), Validators.min(8)]]
    }, {
      validator: ValidaIgualdad('nueva_clave', 'confirmacion_clave')
  })
  }

  /**
   * Retorna a la interfaz principal
   */
  cancelar(){
    this.router.navigate([localStorage.getItem("paginaAnterior")])
  }

  /**
   * Metodo que obtiene los datos ingresados por el usuario y realiza la gestión de cambio de clave
   */
  async cambiarClave(){
    this.submitted = true;
    var resultado;
    let datos: DatosDialogo;

    if (this.formCambioClave.valid) {
      this.login.id_contribuyente = this.id_contribuyente;
      this.login.clave = this.formCambioClave.value.clave;
      this.login.nueva_clave = this.formCambioClave.value.nueva_clave;
      this.login.confirmacion_clave = this.formCambioClave.value.confirmacion_clave;

      try {

        resultado = await this.cambioClaveService.CambioClave(this.login);

        if (resultado == "OK"){
          datos = {
            titulo: "Cambio de clave", 
            mensaje:"El cambio de clave se efectuo con éxito", 
            btnCancelar:false, 
            btnAceptar: true,
            color: "material-icons.color_red",
            icono: "informacion.png"
          }
          
          this.MuestraDialogoAviso(datos)

        }else
          this.mensajeService.Error(resultado);
          
      } catch (error) {
        this.mensajeService.Error("No fue posible realizar la actualización de clave, por favor intente mas tarde");

      }
    }
    else
      this.mensajeService.Advertencia('Todos los campos son obligatorios');
  }

  /**
   * Metodo que abre el dialogo de aviso de cambio de clave
   */
  MuestraDialogoAviso(datosDialogo: DatosDialogo ): any  {
    const dialogRef = this.dialog.open(DialogoAvisoComponent, {
      width: '300px',
      data: datosDialogo
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('Se cerro el dialogo de aviso');

      this.router.navigate([localStorage.getItem("paginaAnterior")])
    });
  }

  /**
   * Actualización del tipo de contribuyente
   */
  onChange(tipo) {
    this.formCambioClave.patchValue({ tipoContribuyente: tipo });
  }
}