import { Component, OnInit, Input } from '@angular/core';
import { Mensaje, TipoDeMensaje } from './Mensaje';
import { MensajeService } from './mensaje.service';
import { Router } from '@angular/router';

@Component({
  moduleId: module.id,
  selector: 'mensaje',
  templateUrl: 'mensaje.component.html',
  styleUrls: ['./mensaje.component.scss']
})

export class MensajeComponent {
  @Input() id: string;

  mensajes: Mensaje[] = [];

  constructor(private MensajesService: MensajeService, private router: Router) { }

  ngOnInit() {
    this.MensajesService.ObtieneAlerta(this.id).subscribe((mensaje: Mensaje) => {
      //if (!mensaje.message) {
        // borra los mensajes cuando un mensaje vacio es recibido
      this.mensajes = [];
      //  return;
      //}

      // agrega el mensaje al arreglo
      this.mensajes.push(mensaje);
    });
  }

  removeAlert(mensaje: Mensaje) {
    this.mensajes = this.mensajes.filter(x => x !== mensaje);
  }

  cssClass(mensaje: Mensaje) {
    if (!mensaje) {
      return;
    }

    // retornamos el css dependiendo el tipo de mensaje
    switch (mensaje.type) {
      case TipoDeMensaje.Exito:
        return 'alert alert-success';
      case TipoDeMensaje.Error:
        return 'alert alert-danger';
      case TipoDeMensaje.Informacion:
        return 'alert alert-info';
      case TipoDeMensaje.Advertencia:
        return 'alert alert-warning';
    }
  }
}
