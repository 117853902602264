import { Injectable } from '@angular/core';

@Injectable()
export class AbrirLinkExternoService{

    constructor() {}

    post(obj, url){

        var formulario = document.createElement("form");

        formulario.target = '_blank';
        formulario.method = 'POST';
        formulario.action = url;

        Object.keys(obj).forEach(function(parametro){
            var formInput = document.createElement("input");
            formInput.type = "hidden";
            formInput.name = parametro;
            formInput.setAttribute('value',obj[parametro]);
            formulario.appendChild(formInput);
        });
        document.body.appendChild(formulario);
        formulario.submit();
    }
}

