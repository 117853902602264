import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginDTO } from '../usuarioDTO';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RecuperarClaveService } from './recuperarClave.service';
import { IdentificarTipoContribuyente } from './../../../Utilerias/Validadores'
import { MensajeService } from 'src/app/Utilerias/mensajes/mensaje.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './recuperarClave.component.html',
  styleUrls: ['./../../servicios/consulta-servicios.component.scss'],
})
export class RecuperarClaveComponent implements OnInit {
  submitted = false;
  formRecuperarClave: FormGroup;
  login: LoginDTO;

  constructor(private router:Router, 
    private fb: FormBuilder, 
    private recuperarClaveService: RecuperarClaveService,
    private mensajeService: MensajeService) { }

  ngOnInit() {
    this.login = new LoginDTO();
    this.CrearForm();
  }

  /**
   * Metodo que crea la forma para el ingreso de los datos para recuperar clave
   */
  CrearForm() {
    this.formRecuperarClave = this.fb.group({
      usuario: ['',[Validators.required]],
      tipoContribuyente: ['',[Validators.required, Validators.min(1), Validators.max(3)]]
    })
  }

  get f() {return this.formRecuperarClave.controls;}

  /**
   * Consume el servicio para recuperar la clave por medio de correo electronico
   */
  async onSubmit(){
    this.submitted = true;
    var resultado;
    var claseContribuyente;

    try {
      // Identificamos el tipo de contribuyente con base al tipo de documento ingresado
      claseContribuyente = IdentificarTipoContribuyente(this.formRecuperarClave.value.usuario);

      if (claseContribuyente==0) 
          return false;

      // Asignamos el tipo de contribuyente con base a tipo de documento de identificación
      this.formRecuperarClave.patchValue({tipoContribuyente: claseContribuyente});

      if (this.formRecuperarClave.valid) {
        this.login.usuario = this.formRecuperarClave.value.usuario;
        this.login.tipoContribuyente = this.formRecuperarClave.value.tipoContribuyente;

        resultado = await this.recuperarClaveService.RecuperarClave(this.login);

        if (resultado == "OK")
          this.mensajeService.Exito("Se ha generado una contraseña y ha sido enviada a su correo");
        else
          this.mensajeService.Advertencia(resultado);
      }
      else
          this.mensajeService.Advertencia('Debe ingresar documento de identificación y tipo de usuario');

    } catch (error) {
      this.mensajeService.Error("No fue posible generar la nueva contraseña: " + error);
    }
  }

   /**
   * Temporamente se actualiza de esta manera el control de tipo de contribuyente
   */
  onChange(tipo) {
    this.formRecuperarClave.patchValue({ tipoContribuyente: tipo });
  }

  /**
   * Metodo que retorna a la interfaz principal, cuando el usuario cancela la operación.
   */
  cancelar(){
    this.router.navigate(["/"]);
  }
}
