import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ParametroReciboArchivo, ParametrosDTO, RecibosPagadosDTO } from "./historialPagosDTO"
import { parseString } from 'xml2js';
import { DatePipe, formatDate } from "@angular/common";
import { OrdenarPorAscendenteDescendente } from 'src/app/Utilerias/arreglos';
import { isNullOrUndefined } from "util";

@Injectable()
export class HistorialPagosService {
  private UrlSOAPComunicaBanco = "comunicabanco/comunicabanco.asmx?op=";
  private recibosPagos: RecibosPagadosDTO;
  private UrlApi = "Coregl/api/comunicabanco";   //URL para QA y otros ambientes
  //private UrlApi = "api/comunicabanco";   //URL para desa

  
  private headers = new HttpHeaders({
    'Content-Type': 'text/xml;charset=UTF-8'
  });

  /**
   * Declaramos esta variable que contiene el esquema del XML para realizar el POST.
   * Se utiliza para la consulta del historial de pagos, se envía como parametro
   */
  private consultaRecibos = `<?xml version="1.0" encoding="utf-8"?>
  <soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">
    <soap12:Body>
      <ObtenerRecibosPagados_Contribuyente xmlns="http://tempuri.org/">
        <intId_Contribuyente>|ID_CONTRIBUYENTE|</intId_Contribuyente>
        <intId_Entidad>|ID_ENTIDAD|</intId_Entidad>
        <fec_inicio>|FECHA_INICIO|</fec_inicio>
        <fec_fin>|FECHA_FIN|</fec_fin>
      </ObtenerRecibosPagados_Contribuyente>
    </soap12:Body>
  </soap12:Envelope>`;

  private obtieneDocumento = `<?xml version="1.0" encoding="utf-8"?>
  <soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">
    <soap12:Body>
      <ObtenerRecibo_Archivo xmlns="http://tempuri.org/">
        <intId_Recibo>|intId_Recibo|</intId_Recibo>
        <strSerie>|strSerie|</strSerie>
        <intNoRecibo>|intNoRecibo|</intNoRecibo>
        <strfecha>|strfecha|</strfecha>
        <intId_Entidad>|intId_Entidad|</intId_Entidad>
        <intEjercicio>|intEjercicio|</intEjercicio>
        <intId_Caja>|intId_Caja|</intId_Caja>
        <strUsuario>|strUsuario|</strUsuario>
        <strDepartamento>|strDepartamento|</strDepartamento>
        <strEstado>|strEstado|</strEstado>
      </ObtenerRecibo_Archivo>
    </soap12:Body>
  </soap12:Envelope>`; 


  /**
   * Definimos todo lo necesario para
   * @param http
   */
  constructor(private http: HttpClient) {}

  /**
   * Realiza la consulta de los municipios del departamento
   * @param id_departamento identificador único del departamento
   */
  async ObtenerHistorialPagos(parametros: ParametrosDTO) {
    var datePipe = new DatePipe('en-US');
    var fechaInicial = datePipe.transform(parametros.fechaInicio, 'dd/MM/yyyy').toString();
    var fechaFinal = datePipe.transform(parametros.fechaFin, 'dd/MM/yyyy').toString();
    var Url = this.UrlApi + '/obtienerecibospagadoscontribuyente';

    //encabezado del api con coregl
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
  
    let bodyParametros = {
      "ID_CONTRIBUYENTE": parametros.id_contribuyente.toString(), 
      "ID_ENTIDAD" : parametros.id_entidad.toString(),
      "FECHA_INICIAL" : fechaInicial,
      "FECHA_FINAL" : fechaFinal
    };

    try {
      const ApiResponse = await this.http.post(Url, bodyParametros, {observe: 'response'}).toPromise();

      //significa que todo salió bien, se devuelve el entero del error o el conjunto de datos
      if(ApiResponse.status == 200 &&  !isNullOrUndefined(ApiResponse.body))
      {
        if(ApiResponse.body["data"] instanceof Array){
          //trae data entonces se arma el arreglo con la estructura correspondiente 
          if (ApiResponse.body["data"].length > 0){
            var recibosPagados = [];
    
            for (var i=0; i < ApiResponse.body["data"].length; i++){
              let recibo: RecibosPagadosDTO = {
                ID_RECIBO: 0,
                SERIE: "",
                NO_RECIBO: 0,
                FECHA: "",
                ID_ENTIDAD: 0,
                EJERCICIO: 0,
                MONTO_DOCUMENTO: 0,
                ID_DOCUMENTO: 0,
                ID_APERTURA: 0,
                ID_CAJA: 0,   
                USUARIO: "",  
                DEPARTAMENTO: "",
                ESTADO: ""
              }
    
              recibo.ID_RECIBO = ApiResponse.body["data"][i][0];
              recibo.SERIE = ApiResponse.body["data"][i][1];
              recibo.NO_RECIBO = ApiResponse.body["data"][i][2];
              recibo.FECHA = ApiResponse.body["data"][i][3];
              recibo.ID_ENTIDAD = ApiResponse.body["data"][i][4];
              recibo.EJERCICIO = ApiResponse.body["data"][i][5];
              recibo.MONTO_DOCUMENTO = ApiResponse.body["data"][i][6];
              recibo.ID_DOCUMENTO = ApiResponse.body["data"][i][7];
              recibo.ID_APERTURA = ApiResponse.body["data"][i][8];
              recibo.ID_CAJA = ApiResponse.body["data"][i][9];
              recibo.USUARIO = ApiResponse.body["data"][i][10];
              recibo.DEPARTAMENTO = ApiResponse.body["data"][i][11];
              recibo.ESTADO = ApiResponse.body["data"][i][12];
    
              recibosPagados.push(recibo);
            } 
            //recibosPagados.sort(OrdenarPorAscendenteDescendente("FECHA","D"))
          return recibosPagados;
          } 
          else 
            return null;
        }
      }
    }
    catch (err)
    {
      console.log("Consulta historial de pagos. Error: " + err)
      this.handleError("No fue posible obtener el historial de pagos");;
    }
  }

  

  /**
   * Metodo que convierte el XML en objeto JSON
   * @param xml string en formato XML
   */
  parseXml(xml) {
    let json = {};
    parseString(xml, { explicitArray: false, ignoreAttrs: true }, (err, result) => {
      json = result;
    });
    return json;
  }

  /**
   * Metodo que realiza la consulta al web services para generar el documento del recibo 
   */
  async ObtenerReciboDePago(parametrosReciboCobro: ParametroReciboArchivo): Promise<Blob> {
    let resultado: Blob;

    var Url = this.UrlApi + '/obtienereciboarchivo';

    //encabezado del api con coregl
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
  
    let bodyParametros = {
      "ID_RECIBO": parametrosReciboCobro.intId_Recibo.toString(), 
      "SERIE" : parametrosReciboCobro.strSerie,
      "NUMERO" : parametrosReciboCobro.intNoRecibo.toString(),
      "ID_ENTIDAD" : parametrosReciboCobro.intId_Entidad.toString(),
      "EJERCICIO": parametrosReciboCobro.intEjercicio.toString(), 
      "ID_CAJA" : parametrosReciboCobro.intId_Caja.toString(),
      "USUARIO_CAJA" : parametrosReciboCobro.strUsuario,
      "DEPARTAMENTO" : parametrosReciboCobro.strDepartamento,
      "ID_DOCUMENTO" : parametrosReciboCobro.intId_Documento,
      "ESTADO" : parametrosReciboCobro.strEstado
    };

    try {
      const ApiResponse = await this.http.post(Url, bodyParametros, {observe: 'response'}).toPromise();

      //significa que todo salió bien, se devuelve el entero del error o el conjunto de datos
      if(ApiResponse.status == 200 &&  !isNullOrUndefined(ApiResponse.body))
      {
        if(ApiResponse.body["data"].length > 0){
          resultado = ApiResponse.body["data"];
          return resultado;      
        }
        else
         return null;
      }
      else
       return null;     
    }catch (err)
    {
      this.handleError(err);
    }
  } 


  /**
   * Metodo para el manejo de errores
   * @param error
   */
  handleError(error: string) {
    console.log(error);
    return Observable.throw(error);
  }  
}
