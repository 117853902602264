export class Mensaje {
    type: TipoDeMensaje;
    message: string;
    alertId: string;
  MantieneLuegoDeCambiarRuta: boolean;

  constructor(init?: Partial<Mensaje>) {
    Object.assign(this, init);
//    (<any>Object).assign(this.success, success.json())
  }
}

export enum TipoDeMensaje {
    Exito,
    Error,
    Informacion,
    Advertencia
}