import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable()
export class PagoBoletoService {
  private UrlSOAP = "comunicabanco/comunicabanco.asmx?op=";
  private UrlApi = "Coregl/api/comunicabanco";   //URL para QA y otros ambientes
  //private UrlApi = "api/comunicabanco";   //URL para desa
    
  private headers = new HttpHeaders({
    'Content-Type': 'text/xml;charset=UTF-8'
  });

  private pagoBoleto = `<?xml version="1.0" encoding="utf-8"?>
                      <soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">
                      <soap12:Body>
                          <WSWM_Genera_Pagar_DocumentoCobroBoletoOrnato xmlns="http://tempuri.org/">
                          <strID_ENTIDAD>|ID_ENTIDAD|</strID_ENTIDAD>
                          <strCorreo>|CORREO|</strCorreo>
                          <strTitularTarjeta>|TITULAR_TARJETA|</strTitularTarjeta>
                          <strMONTO_BOLETO>|MONTO_BOLETO|</strMONTO_BOLETO>
                          <strNoTarjeta>|NO_TARJETA|</strNoTarjeta>
                          <strMesTarjeta>|MES_TARJETA|</strMesTarjeta>
                          <strAnioTarjeta>|ANIO_TARJETA|</strAnioTarjeta>
                          <strCVVTarjeta>|CVV|</strCVVTarjeta>
                          <strID_CONTRIBUYENTE>|ID_CONTRIBUYENTE|</strID_CONTRIBUYENTE>
                          <strCONTRIBUYENTE>|CONTRIBUYENTE|</strCONTRIBUYENTE>
                          </WSWM_Genera_Pagar_DocumentoCobroBoletoOrnato>
                      </soap12:Body>
                      </soap12:Envelope>`;

  
  private aplicaProrrogaBoletoOrnato = `<?xml version="1.0" encoding="utf-8"?>
                      <soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">
                        <soap12:Body>
                          <AplicaProrroga_Boleto_Ornato xmlns="http://tempuri.org/">
                            <ID_ENTIDAD>|ID_ENTIDAD|</ID_ENTIDAD>
                            <EJERCICIO>|EJERCICIO|</EJERCICIO>
                          </AplicaProrroga_Boleto_Ornato>
                        </soap12:Body>
                      </soap12:Envelope>`;

     /**
   * Definimos todo lo necesario para
   * @param http
   */
    constructor(private http: HttpClient) {}

    /**
   * 
   * @param usuario Metodo
   */
  async AplicaProrrogaBoletoOrnato(id_entidad: number, ejercicio: number) {
    var respuesta;

    var Url = this.UrlApi + '/aplicaprorrogaboletoornato';

    //encabezado del api con coregl
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
  
    let bodyParametros = {
      "ID_ENTIDAD": id_entidad.toString(),
      "EJERCICIO": ejercicio.toString()
    };
    
    try {
      const ApiResponse = await this.http.post(Url, bodyParametros, {observe: 'response'}).toPromise();

      respuesta = ApiResponse.body;
      return respuesta;

    }
    catch (err)
	  {
      this.handleError(err);
	  }
  }

   /**
   * 
   * @param usuario Metodo
   */
    async PagosLineaEjercicio(id_entidad: number, ejercicio: number) {
      var respuesta;
 
      var Url = this.UrlApi + '/validapagoslinea_ejercicio';
  
      //encabezado del api con coregl
      let headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
    
      let bodyParametros = {
        "ID_ENTIDAD": id_entidad.toString(),
        "EJERCICIO": ejercicio.toString()
      };
      
      try {
        const ApiResponse = await this.http.post(Url, bodyParametros, {observe: 'response'}).toPromise();
  
        respuesta = ApiResponse.body;
        return respuesta;
  
      }
      catch (err)
      {
        this.handleError(err);
      }
    }


   /**
   * Metodo que genera el documento de pago y realiza el pago del boleto de ornato
   */
  /* async GenerarDocumentoDeCobro(parametrosDocumentoCobro: ParametrosDocumentoDeCobro): Promise<DocumentoCobroDTO> {
    var xmlhttp = new XMLHttpRequest();
    var Url = this.UrlSOAP + 'ConsultaReporte';
    var datos;
    var datosDocumento;
    let resultado: Blob;

    let bodyParametros = this.pagoBoleto.replace('|USUARIO|',this.usuario);
    bodyParametros = bodyParametros.replace('|CLAVE|',this.clave);
    bodyParametros = bodyParametros.replace('|ID_CONTRIBUYENTE|', parametrosDocumentoCobro.id_contribuyente.toString());
    bodyParametros = bodyParametros.replace('|ID_ENTIDAD|', parametrosDocumentoCobro.id_entidad.toString());
    bodyParametros = bodyParametros.replace('|TIPO_REPORTE|', parametrosDocumentoCobro.reporte);
    bodyParametros = bodyParametros.replace('|USUARIO|',this.usuario);

    try {
      xmlhttp.open('POST', Url, false);
      xmlhttp.onreadystatechange = function (data) {
            if (xmlhttp.readyState == 4) {
              if (xmlhttp.status == 200) {
                datos = xmlhttp.responseXML;
              }
            }}

      xmlhttp.setRequestHeader('Content-Type', 'text/xml');
      xmlhttp.send(bodyParametros);

      if (datos.getElementsByTagName("DATA_DOCUMENTO").length > 0) { 
        datosDocumento =  datos.getElementsByTagName("DATA_DOCUMENTO")[0].children;
        resultado = datos.getElementsByTagName("RPT")[0].textContent;

        let documentoCobro: DocumentoCobroDTO = {
          ID_DOCUMENTO : datos.getElementsByTagName("ID_DOCUMENTO")[0].innerHTML,
          NUMERO_DOCUMENTO : datos.getElementsByTagName("NUMERO_DOCUMENTO")[0].innerHTML,
          DESCRIPCION : datos.getElementsByTagName("DESCRIPCION")[0].innerHTML,
          FECHA_DOCUMENTO : datos.getElementsByTagName("FECHA_DOCUMENTO")[0].innerHTML,
          MONTO_DOCUMENTO : datos.getElementsByTagName("MONTO_DOCUMENTO")[0].innerHTML,
          ESTADO : datos.getElementsByTagName("ESTADO")[0].innerHTML,
          ID_PERSONA : datos.getElementsByTagName("ID_PERSONA")[0].innerHTML,
          FECHA_APROBADO : datos.getElementsByTagName("FECHA_APROBADO")[0].innerHTML,
          FECHA_REFERENCIA : datos.getElementsByTagName("FECHA_REFERENCIA")[0].innerHTML,
          OBSERVACIONES : datos.getElementsByTagName("OBSERVACIONES")[0].innerHTML,
          ID_DOCUMENTO_PADRE : datos.getElementsByTagName("ID_DOCUMENTO_PADRE")[0].innerHTML,
          ID_TIPO_DOCUMENTO : datos.getElementsByTagName("ID_TIPO_DOCUMENTO")[0].innerHTML,
          ID_ENTIDAD : datos.getElementsByTagName("ID_ENTIDAD")[0].innerHTML,
          ID_CONTRIBUYENTE : datos.getElementsByTagName("ID_CONTRIBUYENTE")[0].innerHTML,
          DOCUMENTO : resultado
        }

        return documentoCobro;
      }
      else
      {
        return null;
      }
    
    }catch (err)
    {
      this.handleError(err);
    }
  } */

/**
 * Metodo para el manejo de errores
 * @param error
 */
handleError(err: any) {
    console.log(err);
    return Observable.throw(err);
  } 

}
