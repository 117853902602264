import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { parseBooleans } from 'xml2js/lib/processors';
import { CuentaCorrienteDTO } from './detalleReciboDTO';
import { DetalleReciboDTO } from './detalleReciboDTO';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { isNullOrUndefined } from "util";

@Injectable({
  providedIn: 'root'
})
export class ReciboElectronicoService {
  reciboElectronico: DetalleReciboDTO;
  private UrlApi = "Coregl/api/comunicabanco";   //URL para QA y otros ambientes
  //private UrlApi = "api/comunicabanco";   //URL para desa

  constructor(private http: HttpClient) { }

  /**
   * Metodo que obtiene el recibo y su detalle
   */
  async ConsultaReciboElectronico(firma: string){
     var Url = this.UrlApi + '/obtienerecibofirma';

    //encabezado del api con coregl
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
  
    let bodyParametros = {
      "FIRMA": firma
    };
 
     try {
      const ApiResponse = await this.http.post(Url, bodyParametros, {observe: 'response'}).toPromise();

      if (ApiResponse.body["data"].length > 0){
        let recibo: DetalleReciboDTO = {
          ID_RECIBO: 0,
          SERIE: '',
          NO_RECIBO: '',
          FECHA: '',
          NOMBRE: '',
          MONTO_TOTAL: 0,
          ENTIDAD: '',
          DEPARTAMENTO: '',
          ESTADO: '',
          CUENTA_CORRIENTE: [] 
         }
  
         recibo.ID_RECIBO = ApiResponse.body["data"][0][0];
         recibo.SERIE = ApiResponse.body["data"][0][1];
         recibo.NO_RECIBO = ApiResponse.body["data"][0][2];
         recibo.FECHA =ApiResponse.body["data"][0][3];
         recibo.NOMBRE = ApiResponse.body["data"][0][4];
         recibo.ENTIDAD = ApiResponse.body["data"][0][7];;
         recibo.DEPARTAMENTO = ApiResponse.body["data"][0][8];
         recibo.ESTADO = ApiResponse.body["data"][0][10];
  
         for (var i=0; i < ApiResponse.body["data"].length; i++){
          let cuentaCorriente: CuentaCorrienteDTO = {
            ID_CUENTA_CORRIENTE: 0,
            DETALLE_SERVICIO: '',
            MONTO: 0
          };
  
           cuentaCorriente.ID_CUENTA_CORRIENTE = ApiResponse.body["data"][i][5];
           cuentaCorriente.DETALLE_SERVICIO = ApiResponse.body["data"][i][9];
           cuentaCorriente.MONTO = parseFloat(ApiResponse.body["data"][i][6]);
   
           recibo.CUENTA_CORRIENTE.push(cuentaCorriente);
           recibo.MONTO_TOTAL += cuentaCorriente.MONTO;  
           
         }
         return recibo;

      } else 
          return null;

     }
     catch(error){
       this.handleError(error);
     }
   }

     /**
 * Metodo para el manejo de errores
 * @param error
 */
  handleError(err: any) {
    console.log(err);
    return Observable.throw(err);
  } 

}