import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MatTableDataSource, MAT_DATE_FORMATS } from '@angular/material';
import { Router } from '@angular/router';
import { LocalStorageServiceEncrypt } from 'angular-2-local-storage-encrypt';
import { MensajeService } from 'src/app/Utilerias/mensajes/mensaje.service';
import { ParametrosConsultaDTO } from '../consulta/consultaDTO';
import { HistorialPagosService } from './historial-pagos.service'
import { ParametroReciboArchivo, ParametrosDTO, RecibosPagadosDTO } from './historialPagosDTO';
import { AppDateAdapter, APP_DATE_FORMATS} from '../../Utilerias/date.adapter';
import { MatDialog } from '@angular/material';
import { VisorReciboComponent } from './visor/visor-recibo.component';
import { ContribuyenteDTO } from '../usuario/usuarioDTO';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator'
import { map } from 'rxjs/operators';


@Component({
  selector: 'app-historial-pagos',
  templateUrl: './historial-pagos.component.html',
  styleUrls: ['./../servicios/consulta-servicios.component.scss'],
  providers: 
    [
      {provide: DateAdapter, useClass: AppDateAdapter},
      {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}
    ]
  })
export class HistorialPagosComponent implements OnInit {
  contribuyente: ContribuyenteDTO;
  parametros: ParametrosDTO = {id_contribuyente:0, id_entidad:0, fechaInicio: new Date(), fechaFin: new Date()};
  parametrosConsultaDTO: ParametrosConsultaDTO;
  formPeriodo: FormGroup;
  recibosPagados: RecibosPagadosDTO[] = [];
  dataSourceRecibosPagos = null;
  columnasEncabezadoRecibos: string[] = ['ID_RECIBO','NO_RECIBO','SERIE', 'FECHA', 'MONTO_DOCUMENTO', 'ESTADO'];
  parametroReciboArchivo: ParametroReciboArchivo;
  muestraPaginator: boolean = false;
  seleccionoElementoRecibo: boolean = false;
  HayHistorialPagos: boolean = false;
  cargando: boolean = false;
  maxDate = new Date(1999, 3, 3);
  parametroConsulta: ParametrosConsultaDTO;

  @ViewChild(MatPaginator, { static:false }) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
   
  constructor(private router:Router,
    private HistorialPagosService: HistorialPagosService,
    private encryptLocalService: LocalStorageServiceEncrypt,
    private mensajeService: MensajeService,
    private fb: FormBuilder,
    public dialog: MatDialog
    ) { }

  /**
   * Inicializamos el componente
   */
  ngOnInit() {
    this.parametroConsulta = new ParametrosConsultaDTO();
    this.ObtieneParametros();
    this.CrearForm();
    this.cargando = false;
  }

  CrearForm() {
    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();

    this.formPeriodo = this.fb.group({
        inicio: new FormControl(new Date(), Validators.required),
        fin: new FormControl(new Date(), Validators.required)
    });
  }

  /**
   * Metodo que realiza la llamada para obtener los recibos de pago que ha reaizado el contribuyente
   */
  async ObtenerHistorialPagos() {

    this.parametros.id_contribuyente = this.contribuyente.ID_CONTRIBUYENTE;
    this.parametros.id_entidad = this.parametroConsulta.id_entidad;
    this.parametros.fechaInicio = this.formPeriodo.value.inicio;
    this.parametros.fechaFin = this.formPeriodo.value.fin;

    try {
      this.cargando = true;

      this.recibosPagados = await this.HistorialPagosService.ObtenerHistorialPagos(this.parametros);

      if (this.recibosPagados != null){
        this.CargaLosDatosALaTabla();
        this.HayHistorialPagos = true;
      }else
      {
        this.mensajeService.Exito("El contribuyente NO cuenta con pagos realizados en la(s) fecha(s) indicada(s).");
        this.HayHistorialPagos = false;
      }
    }
    catch(err){
      this.mensajeService.Error("No fue posible obtener el historial de pagos. Por favor intente más tarde ");
    }finally {
      this.cargando = false;
    }
  }

  /**
   * Metodo que carga los datos a la tabla HTML
   */
  CargaLosDatosALaTabla() {
  
    this.dataSourceRecibosPagos = new MatTableDataSource<RecibosPagadosDTO>(this.recibosPagados);
    this.dataSourceRecibosPagos.sort = this.sort;
    this.dataSourceRecibosPagos.paginator = this.paginator;
  
  }

  /**
   * Metodo que llama al servicio que genera el recibo de pago
   * @param seleccion 
   */
  async ObtenerReciboArchivo(){

    try {
      this.cargando = true;

      var recibo = await this.HistorialPagosService.ObtenerReciboDePago(this.parametroReciboArchivo);

      if (recibo == null){
        this.mensajeService.Informacion("No se obtuvo ningún documento para su presentación");
        return false;
      }else
      {
        this.EnviaReporteAVisor(recibo);
      }      
    }
    catch(err){
      this.mensajeService.Error("No fue posible obtener copia del recibo de pago.");
    }finally {
      this.cargando = false;
    }
  }

  
  /**
   * Abre la interfaz para ver el documento generado en una ventana modal
   * @param documento documento a visualizar
   */
  EnviaReporteAVisor(recibo:Blob) {

    const dialogRef = this.dialog.open(VisorReciboComponent, {
      width: '80%',
      data:  recibo,
      height: '90%'
    });
  }

  /**
  * Selección de un recibo
  * @param seleccion Metodo que obtiene los datos del item seleccionado
  */
  SeleccionaRecibo(seleccion: RecibosPagadosDTO, id_documento){
    this.parametroReciboArchivo = {
      intId_Recibo : seleccion.ID_RECIBO,
      strSerie : seleccion.SERIE,
      intNoRecibo : seleccion.NO_RECIBO,
      strfecha : seleccion.FECHA,
      intId_Entidad : seleccion.ID_ENTIDAD,
      intEjercicio : seleccion.EJERCICIO,
      intId_Caja : seleccion.ID_CAJA,
      strUsuario : seleccion.USUARIO,
      strDepartamento : seleccion.DEPARTAMENTO,
      strEstado : seleccion.ESTADO,
      intId_Documento : id_documento
    }

    this.seleccionoElementoRecibo = true;
  }

   /**
   * Metodo que lee los parametros del localstorge
   */
  ObtieneParametros() {
    try {  
      this.contribuyente = JSON.parse(this.encryptLocalService.get('contribuyente'));
      this.parametros.id_contribuyente = this.contribuyente.ID_CONTRIBUYENTE;

      this.parametroConsulta = JSON.parse(this.encryptLocalService.get('parametrosConsulta')); //Recuperamos los parametros de la consulta
      localStorage.removeItem('parametrosConsulta');

      this.parametros.id_entidad = this.parametroConsulta.id_entidad;

    } catch (error) { 
      console.log("Metodo -> ObtieneParametros, Error: " + error);
    }
  }

  /**
   * Metodo que retorn a la pagina desde donde fue llamada
   */
  regresar(){
    this.router.navigate([localStorage.getItem("paginaAnterior")]);
  }

  /**
   * función que filtra los datos
   * @param value dato ingresado en el filtro
   */
  public filtrar = (value: string) => {
    this.dataSourceRecibosPagos.filter = value.trim().toLocaleLowerCase();
  }

  Inicializar(){
    this.dataSourceRecibosPagos = null;
    this.HayHistorialPagos = false;
  }
}
