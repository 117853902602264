import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders} from "@angular/common/http";
import { LoginDTO } from "./../usuarioDTO";
import { isNullOrUndefined } from "util";

@Injectable()
export class CambioClaveService {
  public usuario: Observable<LoginDTO>;
  private UrlSOAP = "comunicabanco/comunicabanco.asmx?op=";

  private UrlApi = "Coregl/api/comunicabanco";   //URL para QA y otros ambientes
  //private UrlApi = "api/comunicabanco";   //URL para desa

  private httpBodyCambioClave = `<?xml version="1.0" encoding="utf-8"?>
  <soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">
    <soap12:Body>
      <ActualizarClaveContribuyente xmlns="http://tempuri.org/">
        <intID_Contribuyente>|ID_CONTRIBUYENTE|</intID_Contribuyente>
        <strClaveAnterior>|CLAVE_ANTERIOR|</strClaveAnterior>
        <strNuevaClave>|CLAVE_NUEVA|</strNuevaClave>
      </ActualizarClaveContribuyente>
    </soap12:Body>
  </soap12:Envelope>`;

  private headers = new HttpHeaders({
    'Content-Type': 'text/xml;charset=UTF-8'
  });

  /**
   * Definimos todo lo necesario para
   * @param http
   */
  constructor(private http: HttpClient) {}


  /**
   * Consume el ws SOAP para cambio de clave
   * @param credenciales contiene los datos del usuario (usuario, clave anterior, clave nueva )
   */
   async CambioClave(credenciales: LoginDTO) {
    var respuesta;

    var Url = this.UrlApi + '/actualizaclavecontribuyente';

    try {
      //encabezado del api con coregl
      let headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
    
      let bodyParametros = {
        "ID_CONTRIBUYENTE": credenciales.id_contribuyente.toString(),       
        "STRPWD_ANT" : credenciales.clave.toString(),
        "STRPWD" : credenciales.nueva_clave.toString(),
      };

      const ApiResponse = await this.http.post(Url, bodyParametros, {observe: 'response'}).toPromise();
  
      //significa que todo salió bien, se devuelve el entero del error o el conjunto de datos
      if(ApiResponse.status == 200 &&  !isNullOrUndefined(ApiResponse.body))
      {
        respuesta = ApiResponse["body"]
      }

      return respuesta;

    }
    catch (err)
	  {
      this.handleError(err);
	  }
  }

  /**
   * Metodo para el manejo de errores
   * @param error
   */
  handleError(error: string) {
    console.log(error);
    return Observable.throw(error);
  }
}