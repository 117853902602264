import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DateAdapter, MatPaginator, MatTableDataSource, MAT_DATE_FORMATS } from '@angular/material';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { MensajeService } from 'src/app/Utilerias/mensajes/mensaje.service';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../Utilerias/date.adapter';
import { EntidadDTO } from 'src/app/components/municipalidad/municipalidadDTO';
import { MunicipalidadService } from 'src/app/components/municipalidad/municipalidad.service';
import { consultaPuntoCobroDTO } from 'src/app/pages/informacion-municipalidad/consultaPuntoCobroDTO';
import { MatDialog } from '@angular/material';
import { VisorMapaComponent } from '../visor-mapa/visor-mapa.component';

@Component({
  selector: 'app-informacion-municipalidad',
  templateUrl: './informacion-municipalidad.component.html',
  styleUrls: ['./../servicios/consulta-servicios.component.scss'],
})

export class InformacionMunicipalidadComponent implements OnInit {
  public municipalidad: EntidadDTO;
  public muestraDatos: boolean = false;
  cargando: boolean = false;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @Input() id_entidad: number = 0;
  @Input() municipalidadSeleccionada: EntidadDTO = null;

  columnasEncabezadoContacto: string[] = ['DESCRIPCION', 'DIRECCION', 'WHATSAPP', 'CORREO_ELECTRONICO', 'TELEFONO', 'HORARIO_ATENCION', 'COBROS_POS', 'UBICACION_MAPA'];
  columnasEncabezadoBancaMuni: string[] = ['NOMBRE', 'COBROS_BANCA_VIRTUAL', 'AGENCIAS_MUNICIPALES', 'AGENCIAS_LOCALES', 'AGENCIAS_NACIONALES'];
  dataSourceContacto = null;
  dataSourceBancaMuni = null;
  HayPuntosCobro: boolean = false;
  HayBancaMuni: boolean = false;
  modeloData = [];

  constructor(
    private router: Router,
    private municipalidadService: MunicipalidadService,
    private mensajeService: MensajeService,
    public dialog: MatDialog
  ) { }

  /**
   * Inicializamos el componente
   */
  ngOnInit() {

    this.muestraDatos = false;

    this.municipalidad = {
      ID_DEPARTAMENTO: null,
      DEPARTAMENTO: "",
      DESCRIPCION: "",
      ID_ENTIDAD: null,
      SITIO_WEB: ""
    }
  }

  /**
 * Metodo que recibe la entidad o municipalidad seleccionada
 * @param valor 
 */
  AsignaMunicipalidad(municipalidad: EntidadDTO) {
    if (municipalidad) {
      this.municipalidad = municipalidad;
      this.muestraDatos = true;
    }

    this.ObtenerPuntosCobro(municipalidad.ID_ENTIDAD);
  }

  /**
* Metodo que recibe la entidad o municipalidad seleccionada y obtiene los puntos de cobro
* @param valor 
*/
  async ObtenerPuntosCobro(ID_ENTIDAD: number) {
    try {
      this.modeloData = await this.municipalidadService.ObtenerPuntosCobro(ID_ENTIDAD);

      this.dataSourceContacto = this.modeloData[0];
      this.dataSourceBancaMuni = this.modeloData[1];

      if (this.dataSourceContacto.length > 0) {
        this.HayPuntosCobro = true;
        this.dataSourceContacto.sort = this.sort;
        this.dataSourceContacto.paginator = this.paginator;
      }
      else {
        this.HayPuntosCobro = false;
        this.mensajeService.Advertencia("No fue posible obtener los puntos de cobro de la entidad seleccionada");
      }

      if (this.dataSourceBancaMuni.length > 0) {
        this.HayBancaMuni = true;
      }
      else {
        this.HayBancaMuni = false;
        this.mensajeService.Advertencia("No fue posible obtener la información de bancos de la entidad seleccionada");
      }
    }
    catch (err) {
      console.log(err);
      this.mensajeService.Error("No fue posible obtener los puntos de cobro de la entidad seleecionada, por favor intente más tarde.");
    }
  }

  /**
   * Metodo que consulta multas de transito a tavés de una placa de vehículo
   */
   AbrirMapa(seleccion: consultaPuntoCobroDTO){
      try {       
        const dialogRef = this.dialog.open(VisorMapaComponent, {
          width: '80%',
          data:  seleccion,
          height: '90%'
        });
      } catch (error) {
        console.log("fail" + error);
        this.mensajeService.Advertencia("No fue posible carga la ubicación del punto de cobro.");
      } finally {
        
      }
  }


  /**
   * Metodo que retorna la pagina desde donde fue llamada
   */
  Cerrar() {
    this.router.navigate([localStorage.getItem("paginaAnterior")]);
  }
}
