import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EntidadDTO } from "./consultaDTO";
import { parseString } from 'xml2js';

@Injectable()
export class ConsultaService {
  private UrlSOAP = "comunicacionexterna/munics.asmx?op=";
  private UrlSOAPComunicaBanco = "comunicabanco/comunicabanco.asmx?op=";
  entidades: EntidadDTO[];
  entidad: EntidadDTO;
  
  private headers = new HttpHeaders({
    'Content-Type': 'text/xml;charset=UTF-8'
  });

  /**
   * Declaramos esta variable que contiene el esquema del XML para realizar el POST.
   * El Sector es un número fijo según indicaciones
   */
  private consultaEntidad = `<?xml version="1.0" encoding="utf-8"?>
    <soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">
      <soap12:Body>
        <ObtenerEntidades xmlns="http://tempuri.org/ComunicacionExterna/MuniCS">
          <Sector>1210</Sector>
          <CodigoDepartamento>|ID_DEPARTAMENTO|</CodigoDepartamento>
        </ObtenerEntidades>
      </soap12:Body>
    </soap12:Envelope>`;

    /**
     * Obtiene las municipalidades o entidades que tienen convenio con entidad emisora de tarjetas
     */
    private EntidadesQueAceptanPagos = `<?xml version="1.0" encoding="utf-8"?>
    <soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">
      <soap12:Body>
        <ObtenerEntidadesPago xmlns="http://tempuri.org/">
          <intId_Departamento>|ID_DEPARTAMENTO|</intId_Departamento>
        </ObtenerEntidadesPago>
      </soap12:Body>
    </soap12:Envelope>`;

  /**
   * Definimos todo lo necesario para
   * @param http
   */
  constructor(private http: HttpClient) {}

  /**
   * Realiza la consulta de los municipios del departamento
   * @param id_departamento identificador único del departamento
   */
  ObtenerEntidades(id_departamento: number) {
    var xmlhttp = new XMLHttpRequest();
    var Url = this.UrlSOAP + 'ObtenerEntidades';
    var datos;
    var resultado;
    var items;
    
    let bodyParametros = this.consultaEntidad.replace('|ID_DEPARTAMENTO|',id_departamento.toString());
    try {
      xmlhttp.open('POST', Url, false);
      xmlhttp.onreadystatechange = function (data) {
            if (xmlhttp.readyState == 4) {
              if (xmlhttp.status == 200) {
                datos = xmlhttp.responseXML;
              }
            }}

      xmlhttp.setRequestHeader('Content-Type', 'text/xml');
      xmlhttp.send(bodyParametros);

      resultado = this.parseXml(datos.getElementsByTagName("ObtenerEntidadesResult")[0].textContent);

      // Cargamos los datos al arreglo de municipios
      this.entidades = resultado.NewDataSet.Table;

      return this.entidades;
      
    }
    catch (err)
    {
    this.handleError(err);;
    }
  }

  /**
   * Realiza la consulta de los municipios del departamento
   * @param id_departamento identificador único del departamento
   */
  ObtenerEntidadesAceptanPagoEnLinea(id_departamento: number) {
    var xmlhttp = new XMLHttpRequest();
    var Url = this.UrlSOAPComunicaBanco + 'ObtenerEntidadesPago';
    var datos;
    var resultado;
    var items;
    
    let bodyParametros = this.EntidadesQueAceptanPagos.replace('|ID_DEPARTAMENTO|',id_departamento.toString());
    try {
      xmlhttp.open('POST', Url, false);
      xmlhttp.onreadystatechange = function (data) {
            if (xmlhttp.readyState == 4) {
              if (xmlhttp.status == 200) {
                datos = xmlhttp.responseXML;
              }
            }}

      xmlhttp.setRequestHeader('Content-Type', 'text/xml');
      xmlhttp.send(bodyParametros);

      resultado = datos.getElementsByTagName("Table");

      if (resultado.length > 0){ 
        let detalles = [];

        for (var i=0; i < resultado.length; i++){

          let detalle: EntidadDTO = {
            id_entidad: 0,
            nombre: "",
            id_departamento
          }

          detalle.id_entidad = resultado[i].getElementsByTagName("ID_ENTIDAD")[0].innerHTML;
          detalle.nombre = resultado[i].getElementsByTagName("DESCRIPCION")[0].innerHTML;

          detalles.push(detalle);
        }
        return detalles;
      }
      else
        return null;
    }
    catch (err)
    {
    this.handleError(err);;
    }
  }

  /**
   * Metodo que convierte el XML en objeto JSON
   * @param xml string en formato XML
   */
  parseXml(xml) {
    let json = {};
    parseString(xml, { explicitArray: false, ignoreAttrs: true }, (err, result) => {
      json = result;
    });
    return json;
  }


  /**
   * Metodo para el manejo de errores
   * @param error
   */
  handleError(error: string) {
    console.log(error);
    return Observable.throw(error);
  }  
}
