import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA,  } from "@angular/material";

export interface DatosDialogo {
    titulo: string;
    mensaje: string;
    btnAceptar: boolean;
    btnCancelar: boolean;
    color: string;
    icono: string;
}
  
@Component({
    selector: 'dialogo-aviso',
    templateUrl: 'dialogo-aviso.component.html',
    styleUrls: ['./../../pages/servicios/consulta-servicios.component.scss'],
  })
  export class DialogoAvisoComponent {
  
    constructor(
      public dialogRef: MatDialogRef<DialogoAvisoComponent>,
      @Inject(MAT_DIALOG_DATA) public data: DatosDialogo) {
        
      }
  
      CierraDialogo(): void {
      this.dialogRef.close();
    }
  
  }
