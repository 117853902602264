import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Mensaje, TipoDeMensaje } from './Mensaje';

@Injectable()
export class MensajeService {
  private subject = new Subject<Mensaje>();
  private MantieneLuegoDeCambiarRuta = false;


  constructor(private router: Router) {
    // Borra los mensajes de alerta al cabiar la ruta, a menos que MantieneLuegoDeCambiarRuta = true
    //router.events.subscribe(event => {
    //  if (event instanceof NavigationStart) {
    //    if (this.MantieneLuegoDeCambiarRuta) {
    //      // Mantener para un cambio de ruta
    //      this.MantieneLuegoDeCambiarRuta = false;
    //    } else {
    //      // borrar los mensajes de alerta
    //      this.clear();
    //    }
    //  }
    //});

    router.events.subscribe(event => {
        if (event instanceof NavigationStart) {
            if (this.MantieneLuegoDeCambiarRuta) {
                this.MantieneLuegoDeCambiarRuta =false;
            }else
            {
                this.clear();
            }
        }
    })
  }

  /**
   * * 
   * @param alertId
   */
  ObtieneAlerta(alertId?: string): Observable<any> {
    //return this.subject.asObservable().filter((x: Mensaje) => x && x.alertId === alertId);
    return this.subject.asObservable().pipe(filter((y: Mensaje) => y && y.alertId === alertId));
  }

  /**
   * Metodo que despliega un mensaje al usuario indicando el resultado de la operación realizada
   * @param valor
   */
  DespliegaMensajeOperacionBD(valor: number) {
    if (valor > 0)
      this.Exito('Operación realizada con éxito');
    else if (valor == 0)
      this.Advertencia('El registro que desea agregar ya existe');
    else if (valor == -1)
      this.Error('No fue posible agregar el registro, por favor reportelo a la unidad de Análisis y Desarrollo');
  }

  Exito(message: string) {
    this.MostrarMensaje(new Mensaje({ message, type: TipoDeMensaje.Exito }));
  }

  Error(message: string) {
    this.MostrarMensaje(new Mensaje({ message, type: TipoDeMensaje.Error }));
  }

  Informacion(message: string) {
    this.MostrarMensaje(new Mensaje({ message, type: TipoDeMensaje.Informacion }));
  }

  Advertencia(message: string) {
    if(message != ""){
      this.MostrarMensaje(new Mensaje({ message, type: TipoDeMensaje.Advertencia }));
    }  
  }

  // Metodo que muestra el mensaje
  MostrarMensaje(mensaje: Mensaje) {
    this.MantieneLuegoDeCambiarRuta = mensaje.MantieneLuegoDeCambiarRuta;
    this.subject.next(mensaje);
  }

  // Metodo que limpia los mensajes
  public clear(alertId?: string) {
    this.subject.next(new Mensaje({ alertId }));
  }
}
