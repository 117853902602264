import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { LocalStorageServiceEncrypt } from 'angular-2-local-storage-encrypt';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DetalleReciboDTO } from '../detalleReciboDTO';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { DatosDialogo, DialogoAvisoComponent } from 'src/app/Utilerias/dialogo-aviso/dialogo-aviso.component';

@Component({
  selector: 'app-detalle-recibo',
  templateUrl: './detalle-recibo.component.html',
  styleUrls: ['./../../servicios/consulta-servicios.component.scss']
  //styleUrls: ['./detalle-recibo.component.scss']
})
export class DetalleReciboComponent implements OnInit {
  detalleRecibo: DetalleReciboDTO;
  columnasDetalleRecibo: string[] = ['DETALLE_SERVICIO', 'MONTO'];
  muestraPaginator: boolean;
  formRecibo: FormGroup;
  entidad: string;
  montoTotal: number;
  estado: string;
  public anulado: boolean = false;
  ExisteRecibo: boolean = false;
 
  dataSource = new MatTableDataSource<any>();

  @ViewChild(MatPaginator,{static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  

  constructor(
    private encryptLocalService: LocalStorageServiceEncrypt,
    private fb: FormBuilder,
    private router: Router,
    public dialog: MatDialog
    ) { }
  
    
  ngOnInit() {
    this.ExisteRecibo = false;
    this.ObtieneParametros();
    this.CargaLosDatosALaTabla();
    this.CreaForma();
  }

  CreaForma(){
    this.formRecibo = this.fb.group({
      ID_RECIBO: [this.detalleRecibo.ID_RECIBO,[Validators.required]],
      SERIE: [this.detalleRecibo.SERIE,[Validators.required]],
      NO_RECIBO: [this.detalleRecibo.NO_RECIBO,[Validators.required]],
      FECHA: [this.detalleRecibo.FECHA,[Validators.required]],
      NOMBRE: [this.detalleRecibo.NOMBRE,[Validators.required]],
      MONTO_TOTAL: [this.detalleRecibo.MONTO_TOTAL,[Validators.required]],
      ENTIDAD: [this.detalleRecibo.ENTIDAD,[Validators.required]],
      DEPARTAMENTO: [this.detalleRecibo.DEPARTAMENTO,[Validators.required]],
      ESTADO: [this.detalleRecibo.ESTADO,[Validators.required]],
    });
  }


  /**
   * Metodo que carga los datos a la tabla HTML
   */
  CargaLosDatosALaTabla() {

    this.CanculaMontoTotal();
    this.dataSource.data = this.detalleRecibo.CUENTA_CORRIENTE;

    this.ExisteRecibo = true;
    
    if (this.detalleRecibo.ESTADO == 'ANULADO') {
      this.anulado = true;    

      let datos = {
          titulo: "Recibo anulado", 
          mensaje:"El presente recibo esta anulado, por lo que carece de validez.", 
          btnCancelar:false, 
          btnAceptar: true,
          color: "material-icons.color_red",
          icono: "advertencia.png"
        }
        
        this.MuestraDialogoAviso(datos)

    }
      
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  /**
   * Metodo que abre el dialogo de aviso
   */
   MuestraDialogoAviso(datosDialogo: DatosDialogo ): any  {
    const dialogRef = this.dialog.open(DialogoAvisoComponent, {
      width: '250px',
      data: datosDialogo
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('Se cerro el dialogo de aviso');
    });
  }

  /**
   * Cerrar dialogo
   */
  Cerrar(){
    let paginaAnterior = localStorage.getItem("paginaVerificaRecibo");
    localStorage.removeItem("paginaVerificaRecibo");
    this.router.navigate([paginaAnterior]);
  }

   /**
   * Función que suma el monto total pagado de los conceptos
   */
  CanculaMontoTotal() {
    this.montoTotal = this.detalleRecibo.CUENTA_CORRIENTE.map(s => s.MONTO).reduce((valorPrevio, valorActual) => valorPrevio + valorActual, 0);
   ;
  }

  /**
   * Metodo que lee los parametros del localstorge
   */
  ObtieneParametros() {
    this.detalleRecibo = JSON.parse(this.encryptLocalService.get('recibo')); //Recuperamos la cuenta corriente del servicio
    localStorage.removeItem('recibo');
  }
}