import { Component, Input, OnInit} from '@angular/core';
import { MatBottomSheet } from '@angular/material';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { pagoBoletoDTO, parametrosBoleto } from './pagoBoletoDTO';
import { DepartamentoDTO, EntidadDTO } from '../consulta/consultaDTO';
import { MensajeService } from 'src/app/Utilerias/mensajes/mensaje.service';
import { ConsultaService } from '../consulta/consulta.service';
import { PagoBoletoService } from './pago-boleto.service';
import { AbrirLinkExternoService } from 'src/app/Utilerias/abrirLinkExterno.service';

@Component({
  selector: 'app-pago-boleto',
  templateUrl: './pago-boleto.component.html',
  styleUrls: ['./pago-boleto.component.scss']
})
export class PagoBoletoComponent implements OnInit {
  submitted = false;
  formBoletoDeOrnato: FormGroup;
  pagoBoleto: pagoBoletoDTO;
  entidades: EntidadDTO[];
  departamentos: DepartamentoDTO[];
  formCrearUsuario: FormGroup;
  tieneProrroga: boolean = false;
  mesMaximoProrroga: number = 3; 
  mesMaximoPago: number = 2;
  linkPagos: string;
  valoresBoletoOrnato: number[] = [10,15,50,75,100,150];
  monto: number = 0;
  multa: number = 0;
  total: number = 0;
  muestraAyuda: boolean = false;
  tienePagosLineaEjercicio: boolean = false;

  @Input() id_entidad: number = 0;
  
  constructor(private _bottomSheet: MatBottomSheet, 
    private router:Router,
    private formBuilder: FormBuilder,
    private mensajeService: MensajeService,
    private consultaService: ConsultaService,
    private pagoBoletoService: PagoBoletoService,
    private abrirLinkExternoService: AbrirLinkExternoService) { }

  ngOnInit() {
    this.tieneProrroga = false;
    this.linkPagos = "https://serviciosgl.minfin.gob.gt/Integracion_Pago/frmCredencialesBoleto.aspx"
    //this.departamentos = ObtenerDepartamentos();

    this.CrearForm();
  }

  get f() { return this.formBoletoDeOrnato.controls; }

  /**
   * Metodo que crea el form de la página
   */
  CrearForm() {
    this.formBoletoDeOrnato = this.formBuilder.group(
      {
        id_entidad: [0, [Validators.required, Validators.min(1)]],
        nombres: ['', [Validators.required]],
        apellidos: ['',[Validators.required]],
        dpi: ['']
      }
    )
  }

  /**
 * Metodo que recibe la entidad o municipalidad seleccionada
 * @param valor 
 */
  AsignaEntidad(id_entidad: number){
    this.Verifica_PagosLineaEjercicio(id_entidad);

    this.formBoletoDeOrnato.patchValue({id_entidad: id_entidad});
  }

  /**
   * Metodo que hace la consulta de si la municipalidad tienen prorroga de boleto de ornato
   */
  async VerificaProrroga(id_entidad:number){
    var fecha = new Date();
    var año = fecha.getFullYear(); 
    
    try {
      this.tieneProrroga = await this.pagoBoletoService.AplicaProrrogaBoletoOrnato(id_entidad, año);

      if (!this.tieneProrroga)
        this.mensajeService.Informacion("Excedio el tiempo establecido según la ley, debera pagar multa");
    }
    catch(err){
      this.mensajeService.Error("No fue posible verificar si hay prorroga (pago sin multa)");
    }    
  }

  /**
   * Metodo que hace la consulta de si la municipalidad tienen prorroga de boleto de ornato
   */
   async Verifica_PagosLineaEjercicio(id_entidad:number){
    var fecha = new Date();
    var año = fecha.getFullYear();
    
    try {
      this.tienePagosLineaEjercicio = await this.pagoBoletoService.PagosLineaEjercicio(id_entidad, año);

      if (!this.tienePagosLineaEjercicio)
        this.mensajeService.Informacion("No se puede llevar a cabo el pago de boletos de ornato debido a que la municipalidad no está habilitada para pagos en línea.");
      else
        this.VerificaProrroga(id_entidad);
    }
    catch(err){
      this.mensajeService.Error("No fue posible verificar si hay configuración de pagos en línea para la entidad y el ejercicio");
    }    
  }

  /**
   * Metodo para realizar el pago del boleto de ornato
   */
  PagarBoletoDeOrnato(){
   this.submitted = true;
    var fecha = new Date();
    var textoDPI = this.formBoletoDeOrnato.value.dpi;

    if (textoDPI.length == 0)
      textoDPI = "";
    else
      textoDPI = "  DPI - " + this.formBoletoDeOrnato.value.dpi;

    try {
      if(this.tieneProrroga)
        this.formBoletoDeOrnato.patchValue({multa: 0});
      else
       this.formBoletoDeOrnato.patchValue({multa: this.monto});
      

      if (this.formBoletoDeOrnato.valid){
        let parametrosPago: parametrosBoleto = {
          id_entidad: this.formBoletoDeOrnato.value.id_entidad,
          monto_cobrar: (this.monto + this.multa).toString(),
          monto_sin_multa: this.monto.toString(),
          contribuyente: this.formBoletoDeOrnato.value.nombres + " " + this.formBoletoDeOrnato.value.apellidos + textoDPI
        }

        this.abrirLinkExternoService.post(parametrosPago, this.linkPagos);
      }
      else
      {
        this.mensajeService.Advertencia("Debe completar la información del formulario");
      }
      
    } catch (error) {
      this.mensajeService.Error("Se ha producido un inconveniente para realizar el pago, por favor intente mas tarde");
    }
  }

  /**
   * Metodo que calcula el monto luego de que el contribuyene seleccina el monto
   * @param element Evento que actualiza el valor del monto de boleto de ornato
   */
  SeleccionaMonto(element){
    this.monto = element;
   
    if(this.tieneProrroga)
      this.multa = 0
    else
      this.multa = element;

    this.total = this.monto + this.multa;
  }

  /**
   * Metodo que retorna a la pagina anterior a su llamado
   */
  regresar(){
    this.router.navigate([localStorage.getItem("paginaAnterior")]);
  }

  /**
   * Metodos para mostrar la información
   */
  info(){
    //this._bottomSheet.open(InfoBoletoComponent);
      this.muestraAyuda = !this.muestraAyuda;

  }
}
