import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EntidadDTO } from './municipalidadDTO';
import { MensajeService } from 'src/app/Utilerias/mensajes/mensaje.service';
import { MunicipalidadService } from './municipalidad.service';
import { DepartamentoDTO } from 'src/app/pages/consulta/consultaDTO';
import { OrdenarPorAscendenteDescendente } from 'src/app/Utilerias/arreglos';

@Component({
  selector: 'app-municipalidad',
  templateUrl: './municipalidad.component.html',
  styleUrls: ['./municipalidad.component.scss']
})
export class MunicipalidadComponent implements OnInit {
  entidades: EntidadDTO[];
  entidadesDeptoSeleccionado: EntidadDTO[];
  departamentos: DepartamentoDTO[];
  formMunicipalidad: FormGroup;

  @Output() entidadSeleccionada = new EventEmitter<number>();
  @Output() departamentoSeleccionado = new EventEmitter<number>();
  @Output() municipalidadSeleccionada = new EventEmitter<EntidadDTO>();

  constructor(
    private municipalidadService: MunicipalidadService,
    private mensajeService: MensajeService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.CrearForm();
    this.ObtenerEntidades();
  }

  get f() { return this.formMunicipalidad.controls; }

  /**
   * Metodo que crea el form de la página
   */
   CrearForm() {
    this.formMunicipalidad = this.fb.group(
      {
        id_departamento: [0, [Validators.required, Validators.min(1)]],
        id_entidad: [0, [Validators.required, Validators.min(1)]]
      }
    )
  }

  /**
   * Metodo que llama al servicio para obtener todas las entidades que tienen convenio de pago en linea
   */
   async ObtenerEntidades(){
  
    try {
      this.entidades = await this.municipalidadService.ObtenerEntidadesAceptanPagoEnLinea();

      if (this.entidades.length>0)
        this.departamentos = this.ObtenerDepartamentos();
      else
      {
        this.mensajeService.Advertencia("No fue posible obtener las municipalidades con convenio");
      }

      this.entidades.sort();
    }
    catch(err){
      console.log(err);
      this.mensajeService.Error("No fue posible obtener las municipalidades con convenio, por favor intente mas tarde.");
    }
  }

  /**
   * Metodo que obtiene los departamentos de las municipalidades con convenio,
   * @returns Departamentos
   */
  ObtenerDepartamentos(): DepartamentoDTO[]{
    const d: DepartamentoDTO[] = [];
    
    try {
      let respuesta =  this.entidades.map(entidad => {

        const depto = d.find(departamento => departamento.id_departamento === entidad.ID_DEPARTAMENTO);

        if (depto == undefined){
          let tmp = this.entidades.filter(e => e.ID_DEPARTAMENTO === entidad.ID_DEPARTAMENTO);

          let dep: DepartamentoDTO = {
            id_departamento : entidad.ID_DEPARTAMENTO,
            nombre : entidad.DEPARTAMENTO
          }

          d.push(dep);
        }
      });

      d.sort(OrdenarPorAscendenteDescendente("nombre","A"));
      
      return d;
      
    } catch (error) {
      
    }
  }

  /**
   * Metodo que filtra las entidades con base al departamento
   * @param id_departamento identificador del departamento para el que se requieren las entidades
   */
  async FiltrarEntidades(id_departamento:number){
    const e: EntidadDTO[] = [];
    this.entidadesDeptoSeleccionado = [];
    
    try {       
        let tmp = this.entidades.filter(e => e.ID_DEPARTAMENTO === id_departamento);

        for (var i = 0; i < tmp.length; i++) {
            e.push(tmp[i]);     
        }

      this.entidadesDeptoSeleccionado = e.sort(OrdenarPorAscendenteDescendente("NOMBRE","A"));
      this.departamentoSeleccionado.emit(id_departamento);

    } catch (error) {
      console.log(error);
      this.mensajeService.Advertencia("Se ha presentado un problema al filtar las municipalidades");
    }
  }

  /**
   * Metodo que cambia cuando se selecciona entidad, nos servira para emitir el cambio al control contenedor
   */
  SeleccionaEntidad(id_entidad: number){
    let nuevaEntidad: number = id_entidad;
    this.entidadSeleccionada.emit(nuevaEntidad);

    let municipalidad = this.entidades.filter(e => e.ID_ENTIDAD === id_entidad)[0];
    this.municipalidadSeleccionada.emit(municipalidad);
  }
}
