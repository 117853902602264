import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InitComponent } from './pages/init/init.component';
import { LoginComponent } from './pages/usuario/login/login.component';
import { ConsultaComponent } from './pages/consulta/consulta.component';
import { ConsultaServiciosComponent } from './pages/servicios/consulta-servicios.component';
import { DetalleServicioComponent } from './pages/servicios/detalle-servicio/detalle-servicio.component';
import { CambioClaveComponent } from './pages/usuario/cambio-clave/cambio-clave.component';
import { VerificarReciboComponent } from './pages/recibo-electronico/verificar-recibo.component';
import { VisorComponent } from './pages/visor/visor.component';
import { RecuperarClaveComponent } from './pages/usuario/recuperarClave/recuperarClave.component';
import { CrearUsuarioComponent } from './pages/usuario/crear-usuario/crear-usuario.component';
import { PagoBoletoComponent } from './pages/pago-boleto/pago-boleto.component';
import { DetalleReciboComponent } from './pages/recibo-electronico/detalle-recibo/detalle-recibo.component';
import { HistorialPagosComponent} from './pages/historial-pagos/historial-pagos.component'
import { VisorReciboComponent } from './pages/historial-pagos/visor/visor-recibo.component';
import { CuentaCorrienteComponent } from './pages/servicios/cuenta-corriente/cuenta-corriente.component';
import { PagoDocumentoCobroComponent } from './pages/pago-documento-cobro/pago-documento-cobro.component';
import { InformacionMunicipalidadComponent } from './pages/informacion-municipalidad/informacion-municipalidad.component';
import { ConsultaMultaTransitoComponent } from './pages/consulta-multa-transito/consulta-multa-transito.component';


const routes: Routes = [
  {
    path: 'consulta',
    component: ConsultaComponent
  },
  {
    path: 'consulta-servicio',
    component: ConsultaServiciosComponent
  },
  {
    path: 'detalle-servicio',
    component: DetalleServicioComponent
  },
  {
    path: 'cuenta-corriente',
    component: CuentaCorrienteComponent
  },
  {
    path: '',
    component: LoginComponent
  },
  {
    path: 'cambio-clave',
    component: CambioClaveComponent
  },
  {
    path: 'recuperarClave',
    component: RecuperarClaveComponent
  },
  {
    path: 'verificar-recibo',
    component: VerificarReciboComponent
  },
  {
    path: 'detalle-recibo',
    component: DetalleReciboComponent
  },
  {
    path: 'visor',
    component: VisorComponent
  },
  {
    path: 'crear-usuario',
    component: CrearUsuarioComponent
  },
  {
    path: 'pago-boleto',
    component: PagoBoletoComponent
  },
  {
    path: 'historial-pagos',
    component: HistorialPagosComponent
  },
  {
    path: 'visor-recibo',
    component: VisorReciboComponent
  },
  {
    path: 'pago-documento-cobro',
    component: PagoDocumentoCobroComponent,
  },
  {
    path: 'informacion-municipalidad',
    component: InformacionMunicipalidadComponent
  },
  {
    path: 'consulta-multa-transito',
    component: ConsultaMultaTransitoComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
