import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { LocalStorageServiceEncrypt } from 'angular-2-local-storage-encrypt';
import { join } from 'path';
import { AbrirLinkExternoService } from 'src/app/Utilerias/abrirLinkExterno.service';
import { MensajeService } from 'src/app/Utilerias/mensajes/mensaje.service';
import { ConsultaServiciosService } from '../servicios/consulta-servicios.service';
import { DocumentoCobroDTO, ParametrosPago } from '../servicios/consultaServicioDTO';
import { ContribuyenteDTO } from '../usuario/usuarioDTO';

@Component({
  selector: 'app-visor',
  templateUrl: './visor.component.html',
  styleUrls: ['./../servicios/consulta-servicios.component.scss'],
})
export class VisorComponent implements OnInit {
  public src: Blob;
  public titulo: string = "Documento de cobro"; //TODO: Hay que cambiarlo por el parametro enviado para el docomento que se muestre
  aceptaPagosEnLinea: boolean = false;
  datos: DocumentoCobroDTO;
  contribuyente: ContribuyenteDTO;
  linkPagos: string = 'https://serviciosgl.minfin.gob.gt/Integracion_Pago/frmCredenciales.aspx';
  tienePagosLineaEjercicio: boolean = false;

  constructor(private router:Router,
    private dialogRef: MatDialogRef<VisorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
    private consultaServiciosService: ConsultaServiciosService,
    private mensajeService: MensajeService,
    private encryptLocalService: LocalStorageServiceEncrypt,
    private abrirLinkExternoService: AbrirLinkExternoService
    ) { }

  ngOnInit() {  
    this.ReconstruyeLosParametros();
    this.AplicaPagosOnLine();
    this.DesplegarDocumentoDeCobroEnPDF();
  }

  /**
   * Metodo que reconstruye los parametros (documento de cobro)
   */
  ReconstruyeLosParametros() {
    this.datos = JSON.parse(this.data);
    this.contribuyente = JSON.parse(this.encryptLocalService.get('contribuyente'));
  }

  regresar(){
    this.router.navigate(['/consulta-servicios']);
  }

  /**
   * Metodo que lee los parametros del localstorge
   */
 async DesplegarDocumentoDeCobroEnPDF() {
    //const base64Data = JSON.parse(localStorage.getItem('documentoCobro'));
    //const base64Response = await fetch(`data:application/pdf;base64,${base64Data}`);
    //this.src = await base64Response.blob();
    //localStorage.removeItem('documentoCobro');
    //const documento: DocumentoCobroDTO = JSON.parse(this.data);
    //const base64Data = JSON.parse(this.data);
    const base64Response = await fetch(`data:application/pdf;base64,${this.datos.DOCUMENTO}`);
    this.src = await base64Response.blob();
  }

  /**
   * Metodo que valida que la municipalidad pueda recibir pagos en linea
   */
  async AplicaPagosOnLine() {
    try {
      this.aceptaPagosEnLinea = await this.consultaServiciosService.AplicaPagosEnLinea(this.datos.ID_ENTIDAD);

      if (this.aceptaPagosEnLinea)
        this.Verifica_PagosLineaEjercicio(this.datos.ID_ENTIDAD);
      this.consultaServiciosService.aplicaPagosEnLinea$.emit(this.aceptaPagosEnLinea);
    }
    catch(err){
      this.mensajeService.Error("No fue posible verificar si la municipalidad acepta pagos en linea." + err);
    }
  }

  /**
   * Metodo que hace la consulta de si la municipalidad tienen prorroga de boleto de ornato
   */
   async Verifica_PagosLineaEjercicio(id_entidad:number){
    var fecha = new Date();
    var año = fecha.getFullYear();
    
    try {
      this.tienePagosLineaEjercicio = await this.consultaServiciosService.PagosLineaEjercicio(id_entidad, año);

      if (!this.tienePagosLineaEjercicio)  
        this.mensajeService.Informacion("No se puede llevar a cabo el pago de documento de cobros debido a que la municipalidad no está habilitada para pagos en línea.");
    }
    catch(err){
      this.mensajeService.Error("No fue posible verificar si hay configuración de pagos en línea para la entidad y el ejercicio");
    }    
  }

  /**
   * Metodo que abre la interfaz para el pago de los servicios
   */
  RealizarPagoDocumentoCobro(){
    //this.documentoCobro = JSON.parse(this.encryptLocalService.get('documentoCobro')); //Recuperamos los parametros de la consulta
    //localStorage.removeItem('documentoCobro');

    try {
      //var param = {'id_entidad':'33', 'no_documento':'5451812', 'monto_documento':'104.81','id_contribuyente':'82991', 'contribuyente':'LETICIA MARQUEZ ROCHE MADRID'};
      console.log("click en pago de documento " + this.contribuyente.CORREO_ELECTRONICO + " // " + this.contribuyente.NOMBRE + " // " + (this.contribuyente.NOMBRE_COMPLETO==null ? "" : this.contribuyente.NOMBRE_COMPLETO)) ;
      if (this.datos.MONTO_DOCUMENTO > 0){
        let parametrosPago: ParametrosPago = {
          id_entidad: this.datos.ID_ENTIDAD,
          no_documento: this.datos.NUMERO_DOCUMENTO,
          monto_documento: this.datos.MONTO_DOCUMENTO,
          id_contribuyente: this.datos.ID_CONTRIBUYENTE,
          contribuyente: this.contribuyente.NOMBRE_COMPLETO,
          correo: this.contribuyente.CORREO_ELECTRONICO
        }
        
        this.abrirLinkExternoService.post(parametrosPago, this.linkPagos);
        
      }
      else
      {
        this.mensajeService.Advertencia("No existe información para realizar el cobro");
      }
      
    } catch (error) {
      console.log("No se pudo redirecciónar a " + this.linkPagos + " error: " + error);
      this.mensajeService.Error("Se ha producido un inconveniente para realizar el pago, por favor intente mas tarde");
      //this.gestorErroresService.handleError("Metodo -> RealizarPagoDocumentoCobro, Error: " + error);
    }
    
  }

  /**
   * Cerrar dialogo
   */
  Cerrar(){
    this.dialogRef.close();
  }
}
